import { Box, Button, Card, Grid, Group } from "@mantine/core";
import { ResponsiveChoropleth } from "@nivo/geo";
import worldCountries from "../data/world_countries.json";
import { ResponsiveCalendar } from "@nivo/calendar";
import { ResponsiveLine } from "@nivo/line";
import { ResponsivePie } from "@nivo/pie";
import { PageTitle } from "../../../layouts/master/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faList } from "@fortawesome/pro-regular-svg-icons";

const SuperadminDashboard = () => {
  return (
    <>
      <PageTitle
        actions={
          <Group sx={{ flex: "1" }} position="right">
            <Button
              variant="light"
              leftIcon={<FontAwesomeIcon icon={faList} />}
            >
              Arrange
            </Button>
            <Button leftIcon={<FontAwesomeIcon icon={faCog} />}>
              Configure
            </Button>
          </Group>
        }
      >
        Dashboard
      </PageTitle>
      <Box mt="xl">
        <Grid>
          <Grid.Col span={8}>
            <Card sx={{ height: "500px" }}>
              <ResponsiveChoropleth
                data={choroplethData}
                features={worldCountries.features}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                colors="nivo"
                domain={[0, 1000000]}
                unknownColor="#666666"
                label="properties.name"
                valueFormat=".2s"
                projectionTranslation={[0.5, 0.5]}
                projectionRotation={[0, 0, 0]}
                enableGraticule={true}
                graticuleLineColor="#dddddd"
                borderWidth={0.5}
                borderColor="#152538"
                legends={[
                  {
                    anchor: "bottom-left",
                    direction: "column",
                    justify: true,
                    translateX: 20,
                    translateY: -100,
                    itemsSpacing: 0,
                    itemWidth: 94,
                    itemHeight: 18,
                    itemDirection: "left-to-right",
                    itemTextColor: "#444444",
                    itemOpacity: 0.85,
                    symbolSize: 18,
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: "#000000",
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
              />
            </Card>
          </Grid.Col>
          <Grid.Col span={4}>
            <Card sx={{ height: "500px" }}>
              <ResponsiveCalendar
                data={calendarData}
                from="2021-03-01"
                to="2022-07-12"
                emptyColor="#eeeeee"
                colors={["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"]}
                margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                yearSpacing={40}
                monthBorderColor="#ffffff"
                dayBorderWidth={2}
                dayBorderColor="#ffffff"
                legends={[
                  {
                    anchor: "bottom-right",
                    direction: "row",
                    translateY: 36,
                    itemCount: 4,
                    itemWidth: 42,
                    itemHeight: 36,
                    itemsSpacing: 14,
                    itemDirection: "right-to-left",
                  },
                ]}
              />
            </Card>
          </Grid.Col>
          <Grid.Col span={4}>
            <Card sx={{ height: "500px" }}>
              <ResponsivePie
                data={pieData}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 0.2]],
                }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: "color" }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                  from: "color",
                  modifiers: [["darker", 2]],
                }}
                defs={[
                  {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "rgba(255, 255, 255, 0.3)",
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "rgba(255, 255, 255, 0.3)",
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                fill={[
                  {
                    match: {
                      id: "ruby",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "c",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "go",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "python",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "scala",
                    },
                    id: "lines",
                  },
                  {
                    match: {
                      id: "lisp",
                    },
                    id: "lines",
                  },
                  {
                    match: {
                      id: "elixir",
                    },
                    id: "lines",
                  },
                  {
                    match: {
                      id: "javascript",
                    },
                    id: "lines",
                  },
                ]}
                legends={[
                  {
                    anchor: "bottom",
                    direction: "row",
                    justify: false,
                    translateX: 0,
                    translateY: 56,
                    itemsSpacing: 0,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: "#999",
                    itemDirection: "left-to-right",
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: "circle",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: "#000",
                        },
                      },
                    ],
                  },
                ]}
              />
            </Card>
          </Grid.Col>
          <Grid.Col span={8}>
            <Card sx={{ height: "500px" }}>
              <ResponsiveLine
                data={lineData}
                margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                xScale={{ type: "point" }}
                yScale={{
                  type: "linear",
                  min: "auto",
                  max: "auto",
                  stacked: true,
                  reverse: false,
                }}
                yFormat=" >-.2f"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "transportation",
                  legendOffset: 36,
                  legendPosition: "middle",
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "count",
                  legendOffset: -40,
                  legendPosition: "middle",
                }}
                pointSize={10}
                pointColor={{ theme: "background" }}
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={[
                  {
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: "left-to-right",
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: "circle",
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemBackground: "rgba(0, 0, 0, .03)",
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
              />
            </Card>
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
};

export default SuperadminDashboard;

const choroplethData = [
  {
    id: "AFG",
    value: 274186,
  },
  {
    id: "AGO",
    value: 793985,
  },
  {
    id: "ALB",
    value: 615094,
  },
  {
    id: "ARE",
    value: 311744,
  },
  {
    id: "ARG",
    value: 334549,
  },
  {
    id: "ARM",
    value: 292053,
  },
  {
    id: "ATA",
    value: 807541,
  },
  {
    id: "ATF",
    value: 825628,
  },
  {
    id: "AUT",
    value: 721627,
  },
  {
    id: "AZE",
    value: 892693,
  },
  {
    id: "BDI",
    value: 868321,
  },
  {
    id: "BEL",
    value: 551418,
  },
  {
    id: "BEN",
    value: 501718,
  },
  {
    id: "BFA",
    value: 518483,
  },
  {
    id: "BGD",
    value: 53653,
  },
  {
    id: "BGR",
    value: 283345,
  },
  {
    id: "BHS",
    value: 683143,
  },
  {
    id: "BIH",
    value: 173288,
  },
  {
    id: "BLR",
    value: 534605,
  },
  {
    id: "BLZ",
    value: 345381,
  },
  {
    id: "BOL",
    value: 529116,
  },
  {
    id: "BRN",
    value: 345976,
  },
  {
    id: "BTN",
    value: 998855,
  },
  {
    id: "BWA",
    value: 963788,
  },
  {
    id: "CAF",
    value: 907363,
  },
  {
    id: "CAN",
    value: 156317,
  },
  {
    id: "CHE",
    value: 422265,
  },
  {
    id: "CHL",
    value: 614779,
  },
  {
    id: "CHN",
    value: 670416,
  },
  {
    id: "CIV",
    value: 7982,
  },
  {
    id: "CMR",
    value: 865865,
  },
  {
    id: "COG",
    value: 367978,
  },
  {
    id: "COL",
    value: 688214,
  },
  {
    id: "CRI",
    value: 799960,
  },
  {
    id: "CUB",
    value: 438629,
  },
  {
    id: "-99",
    value: 733954,
  },
  {
    id: "CYP",
    value: 985027,
  },
  {
    id: "CZE",
    value: 862336,
  },
  {
    id: "DEU",
    value: 930956,
  },
  {
    id: "DJI",
    value: 941019,
  },
  {
    id: "DNK",
    value: 157560,
  },
  {
    id: "DOM",
    value: 821410,
  },
  {
    id: "DZA",
    value: 588822,
  },
  {
    id: "ECU",
    value: 329347,
  },
  {
    id: "EGY",
    value: 427299,
  },
  {
    id: "ERI",
    value: 247774,
  },
  {
    id: "ESP",
    value: 991501,
  },
  {
    id: "EST",
    value: 67989,
  },
  {
    id: "ETH",
    value: 581767,
  },
  {
    id: "FIN",
    value: 759059,
  },
  {
    id: "FJI",
    value: 252159,
  },
  {
    id: "FLK",
    value: 351947,
  },
  {
    id: "FRA",
    value: 378832,
  },
  {
    id: "GAB",
    value: 633849,
  },
  {
    id: "GBR",
    value: 466941,
  },
  {
    id: "GEO",
    value: 29631,
  },
  {
    id: "GHA",
    value: 539778,
  },
  {
    id: "GIN",
    value: 6497,
  },
  {
    id: "GMB",
    value: 121232,
  },
  {
    id: "GNB",
    value: 585211,
  },
  {
    id: "GNQ",
    value: 849612,
  },
  {
    id: "GRC",
    value: 381354,
  },
  {
    id: "GTM",
    value: 950385,
  },
  {
    id: "GUY",
    value: 293538,
  },
  {
    id: "HND",
    value: 182888,
  },
  {
    id: "HRV",
    value: 292152,
  },
  {
    id: "HTI",
    value: 188088,
  },
  {
    id: "HUN",
    value: 507216,
  },
  {
    id: "IDN",
    value: 369916,
  },
  {
    id: "IND",
    value: 358721,
  },
  {
    id: "IRL",
    value: 621469,
  },
  {
    id: "IRN",
    value: 120072,
  },
  {
    id: "IRQ",
    value: 236498,
  },
  {
    id: "ISL",
    value: 219124,
  },
  {
    id: "ISR",
    value: 353367,
  },
  {
    id: "ITA",
    value: 868474,
  },
  {
    id: "JAM",
    value: 319421,
  },
  {
    id: "JOR",
    value: 244755,
  },
  {
    id: "JPN",
    value: 937366,
  },
  {
    id: "KAZ",
    value: 948987,
  },
  {
    id: "KEN",
    value: 250985,
  },
  {
    id: "KGZ",
    value: 383050,
  },
  {
    id: "KHM",
    value: 872693,
  },
  {
    id: "OSA",
    value: 769710,
  },
  {
    id: "KWT",
    value: 141082,
  },
  {
    id: "LAO",
    value: 166358,
  },
  {
    id: "LBN",
    value: 502109,
  },
  {
    id: "LBR",
    value: 115450,
  },
  {
    id: "LBY",
    value: 405631,
  },
  {
    id: "LKA",
    value: 421964,
  },
  {
    id: "LSO",
    value: 456747,
  },
  {
    id: "LTU",
    value: 963444,
  },
  {
    id: "LUX",
    value: 501342,
  },
  {
    id: "LVA",
    value: 78045,
  },
  {
    id: "MAR",
    value: 773830,
  },
  {
    id: "MDA",
    value: 346565,
  },
  {
    id: "MDG",
    value: 325999,
  },
  {
    id: "MEX",
    value: 958704,
  },
  {
    id: "MKD",
    value: 639982,
  },
  {
    id: "MLI",
    value: 269071,
  },
  {
    id: "MMR",
    value: 233195,
  },
  {
    id: "MNE",
    value: 839314,
  },
  {
    id: "MNG",
    value: 868426,
  },
  {
    id: "MOZ",
    value: 945963,
  },
  {
    id: "MRT",
    value: 792897,
  },
  {
    id: "MWI",
    value: 887731,
  },
  {
    id: "MYS",
    value: 836812,
  },
  {
    id: "NAM",
    value: 456764,
  },
  {
    id: "NCL",
    value: 170174,
  },
  {
    id: "NER",
    value: 956388,
  },
  {
    id: "NGA",
    value: 485996,
  },
  {
    id: "NIC",
    value: 361446,
  },
  {
    id: "NLD",
    value: 814276,
  },
  {
    id: "NOR",
    value: 35434,
  },
  {
    id: "NPL",
    value: 507809,
  },
  {
    id: "NZL",
    value: 802336,
  },
  {
    id: "OMN",
    value: 183968,
  },
  {
    id: "PAK",
    value: 169850,
  },
  {
    id: "PAN",
    value: 721994,
  },
  {
    id: "PER",
    value: 140255,
  },
  {
    id: "PHL",
    value: 162891,
  },
  {
    id: "PNG",
    value: 70074,
  },
  {
    id: "POL",
    value: 800893,
  },
  {
    id: "PRI",
    value: 291563,
  },
  {
    id: "PRT",
    value: 351542,
  },
  {
    id: "PRY",
    value: 401013,
  },
  {
    id: "QAT",
    value: 150017,
  },
  {
    id: "ROU",
    value: 901145,
  },
  {
    id: "RUS",
    value: 109320,
  },
  {
    id: "RWA",
    value: 892060,
  },
  {
    id: "ESH",
    value: 236094,
  },
  {
    id: "SAU",
    value: 663196,
  },
  {
    id: "SDN",
    value: 869109,
  },
  {
    id: "SDS",
    value: 886110,
  },
  {
    id: "SEN",
    value: 996683,
  },
  {
    id: "SLB",
    value: 418521,
  },
  {
    id: "SLE",
    value: 168208,
  },
  {
    id: "SLV",
    value: 381327,
  },
  {
    id: "ABV",
    value: 413172,
  },
  {
    id: "SOM",
    value: 6773,
  },
  {
    id: "SRB",
    value: 619437,
  },
  {
    id: "SUR",
    value: 434346,
  },
  {
    id: "SVK",
    value: 720274,
  },
  {
    id: "SVN",
    value: 384293,
  },
  {
    id: "SWZ",
    value: 635703,
  },
  {
    id: "SYR",
    value: 810437,
  },
  {
    id: "TCD",
    value: 618929,
  },
  {
    id: "TGO",
    value: 342081,
  },
  {
    id: "THA",
    value: 314276,
  },
  {
    id: "TJK",
    value: 129702,
  },
  {
    id: "TKM",
    value: 464617,
  },
  {
    id: "TLS",
    value: 320677,
  },
  {
    id: "TTO",
    value: 156228,
  },
  {
    id: "TUN",
    value: 740668,
  },
  {
    id: "TUR",
    value: 599414,
  },
  {
    id: "TWN",
    value: 961090,
  },
  {
    id: "TZA",
    value: 941211,
  },
  {
    id: "UGA",
    value: 717985,
  },
  {
    id: "UKR",
    value: 665521,
  },
  {
    id: "URY",
    value: 671281,
  },
  {
    id: "USA",
    value: 269584,
  },
  {
    id: "UZB",
    value: 751614,
  },
  {
    id: "VEN",
    value: 626800,
  },
  {
    id: "VNM",
    value: 721977,
  },
  {
    id: "VUT",
    value: 438352,
  },
  {
    id: "PSE",
    value: 617674,
  },
  {
    id: "YEM",
    value: 532125,
  },
  {
    id: "ZAF",
    value: 848072,
  },
  {
    id: "ZMB",
    value: 436314,
  },
  {
    id: "ZWE",
    value: 626976,
  },
  {
    id: "KOR",
    value: 400365,
  },
];

const calendarData = [
  {
    value: 115,
    day: "2021-01-27",
  },
  {
    value: 297,
    day: "2019-07-26",
  },
  {
    value: 98,
    day: "2019-08-04",
  },
  {
    value: 240,
    day: "2019-07-19",
  },
  {
    value: 222,
    day: "2021-05-04",
  },
  {
    value: 126,
    day: "2021-06-10",
  },
  {
    value: 301,
    day: "2021-11-22",
  },
  {
    value: 213,
    day: "2020-04-16",
  },
  {
    value: 135,
    day: "2019-10-07",
  },
  {
    value: 5,
    day: "2019-09-03",
  },
  {
    value: 212,
    day: "2020-10-22",
  },
  {
    value: 181,
    day: "2019-05-07",
  },
  {
    value: 381,
    day: "2020-01-03",
  },
  {
    value: 109,
    day: "2021-11-30",
  },
  {
    value: 115,
    day: "2021-05-29",
  },
  {
    value: 82,
    day: "2022-07-30",
  },
  {
    value: 59,
    day: "2022-06-12",
  },
  {
    value: 108,
    day: "2022-05-26",
  },
  {
    value: 1,
    day: "2021-10-16",
  },
  {
    value: 42,
    day: "2020-03-05",
  },
  {
    value: 398,
    day: "2019-07-17",
  },
  {
    value: 66,
    day: "2020-03-07",
  },
  {
    value: 186,
    day: "2021-05-23",
  },
  {
    value: 366,
    day: "2021-08-01",
  },
  {
    value: 255,
    day: "2021-11-13",
  },
  {
    value: 270,
    day: "2020-01-05",
  },
  {
    value: 173,
    day: "2021-12-18",
  },
  {
    value: 216,
    day: "2021-01-20",
  },
  {
    value: 251,
    day: "2020-11-27",
  },
  {
    value: 230,
    day: "2022-05-14",
  },
  {
    value: 246,
    day: "2020-04-25",
  },
  {
    value: 73,
    day: "2020-10-12",
  },
  {
    value: 199,
    day: "2020-09-26",
  },
  {
    value: 278,
    day: "2020-02-08",
  },
  {
    value: 183,
    day: "2022-01-26",
  },
  {
    value: 223,
    day: "2021-04-23",
  },
  {
    value: 263,
    day: "2019-06-04",
  },
  {
    value: 169,
    day: "2022-03-09",
  },
  {
    value: 99,
    day: "2019-06-12",
  },
  {
    value: 115,
    day: "2020-02-17",
  },
  {
    value: 310,
    day: "2022-06-09",
  },
  {
    value: 77,
    day: "2021-07-27",
  },
  {
    value: 84,
    day: "2020-05-14",
  },
  {
    value: 48,
    day: "2021-10-15",
  },
  {
    value: 14,
    day: "2019-07-14",
  },
  {
    value: 252,
    day: "2020-11-30",
  },
  {
    value: 241,
    day: "2021-04-13",
  },
  {
    value: 275,
    day: "2020-03-24",
  },
  {
    value: 276,
    day: "2021-06-25",
  },
  {
    value: 299,
    day: "2022-07-25",
  },
  {
    value: 57,
    day: "2022-03-11",
  },
  {
    value: 367,
    day: "2019-09-06",
  },
  {
    value: 247,
    day: "2020-11-15",
  },
  {
    value: 248,
    day: "2020-04-01",
  },
  {
    value: 220,
    day: "2019-12-10",
  },
  {
    value: 320,
    day: "2022-05-03",
  },
  {
    value: 265,
    day: "2019-06-11",
  },
  {
    value: 108,
    day: "2021-06-05",
  },
  {
    value: 120,
    day: "2019-12-18",
  },
  {
    value: 199,
    day: "2021-10-31",
  },
  {
    value: 48,
    day: "2020-06-16",
  },
  {
    value: 251,
    day: "2019-05-02",
  },
  {
    value: 270,
    day: "2019-10-19",
  },
  {
    value: 110,
    day: "2019-10-14",
  },
  {
    value: 105,
    day: "2019-08-11",
  },
  {
    value: 160,
    day: "2020-05-31",
  },
  {
    value: 65,
    day: "2021-01-28",
  },
  {
    value: 153,
    day: "2020-06-19",
  },
  {
    value: 396,
    day: "2021-04-19",
  },
  {
    value: 220,
    day: "2019-12-01",
  },
  {
    value: 250,
    day: "2019-10-22",
  },
  {
    value: 239,
    day: "2020-01-11",
  },
  {
    value: 53,
    day: "2022-04-18",
  },
  {
    value: 398,
    day: "2021-06-13",
  },
  {
    value: 396,
    day: "2019-09-27",
  },
  {
    value: 110,
    day: "2020-01-07",
  },
  {
    value: 208,
    day: "2019-11-18",
  },
  {
    value: 340,
    day: "2021-07-12",
  },
  {
    value: 244,
    day: "2019-12-24",
  },
  {
    value: 83,
    day: "2021-02-19",
  },
  {
    value: 358,
    day: "2021-08-20",
  },
  {
    value: 326,
    day: "2020-10-03",
  },
  {
    value: 281,
    day: "2019-10-20",
  },
  {
    value: 305,
    day: "2019-07-25",
  },
  {
    value: 157,
    day: "2021-04-05",
  },
  {
    value: 69,
    day: "2022-06-18",
  },
  {
    value: 215,
    day: "2021-07-14",
  },
  {
    value: 388,
    day: "2019-06-01",
  },
  {
    value: 148,
    day: "2019-07-10",
  },
  {
    value: 100,
    day: "2020-07-17",
  },
  {
    value: 173,
    day: "2022-06-13",
  },
  {
    value: 4,
    day: "2021-11-09",
  },
  {
    value: 223,
    day: "2020-05-23",
  },
  {
    value: 165,
    day: "2021-02-07",
  },
  {
    value: 62,
    day: "2019-07-11",
  },
  {
    value: 230,
    day: "2021-02-23",
  },
  {
    value: 366,
    day: "2020-07-31",
  },
  {
    value: 140,
    day: "2019-11-20",
  },
  {
    value: 46,
    day: "2022-04-27",
  },
  {
    value: 361,
    day: "2021-08-07",
  },
  {
    value: 10,
    day: "2019-07-08",
  },
  {
    value: 43,
    day: "2022-03-17",
  },
  {
    value: 37,
    day: "2021-02-11",
  },
  {
    value: 113,
    day: "2020-08-13",
  },
  {
    value: 389,
    day: "2020-04-10",
  },
  {
    value: 185,
    day: "2020-12-07",
  },
  {
    value: 18,
    day: "2019-04-26",
  },
  {
    value: 195,
    day: "2019-11-24",
  },
  {
    value: 122,
    day: "2022-04-07",
  },
  {
    value: 14,
    day: "2020-10-06",
  },
  {
    value: 15,
    day: "2019-08-15",
  },
  {
    value: 279,
    day: "2021-09-29",
  },
  {
    value: 361,
    day: "2022-02-19",
  },
  {
    value: 63,
    day: "2019-09-05",
  },
  {
    value: 151,
    day: "2021-02-14",
  },
  {
    value: 13,
    day: "2019-06-21",
  },
  {
    value: 240,
    day: "2020-12-25",
  },
  {
    value: 390,
    day: "2022-04-21",
  },
  {
    value: 78,
    day: "2022-06-01",
  },
  {
    value: 197,
    day: "2021-01-11",
  },
  {
    value: 76,
    day: "2022-01-21",
  },
  {
    value: 190,
    day: "2021-06-07",
  },
  {
    value: 46,
    day: "2020-07-26",
  },
  {
    value: 116,
    day: "2019-10-16",
  },
  {
    value: 206,
    day: "2022-03-14",
  },
  {
    value: 111,
    day: "2020-03-22",
  },
  {
    value: 177,
    day: "2022-08-02",
  },
  {
    value: 3,
    day: "2021-12-10",
  },
  {
    value: 167,
    day: "2019-12-05",
  },
  {
    value: 284,
    day: "2022-01-05",
  },
  {
    value: 90,
    day: "2020-02-28",
  },
  {
    value: 185,
    day: "2022-05-17",
  },
  {
    value: 44,
    day: "2021-06-26",
  },
  {
    value: 40,
    day: "2021-08-12",
  },
  {
    value: 153,
    day: "2020-01-22",
  },
  {
    value: 161,
    day: "2020-05-19",
  },
  {
    value: 135,
    day: "2022-01-22",
  },
  {
    value: 208,
    day: "2020-05-02",
  },
  {
    value: 179,
    day: "2021-12-31",
  },
  {
    value: 174,
    day: "2021-11-24",
  },
  {
    value: 20,
    day: "2021-07-01",
  },
  {
    value: 158,
    day: "2019-04-11",
  },
  {
    value: 3,
    day: "2019-10-21",
  },
  {
    value: 171,
    day: "2021-12-03",
  },
  {
    value: 126,
    day: "2022-04-23",
  },
  {
    value: 317,
    day: "2021-09-23",
  },
  {
    value: 39,
    day: "2020-08-08",
  },
  {
    value: 346,
    day: "2020-04-05",
  },
  {
    value: 221,
    day: "2022-03-10",
  },
  {
    value: 78,
    day: "2019-04-15",
  },
  {
    value: 155,
    day: "2020-02-24",
  },
  {
    value: 194,
    day: "2021-06-27",
  },
  {
    value: 275,
    day: "2020-08-20",
  },
  {
    value: 196,
    day: "2021-06-19",
  },
  {
    value: 284,
    day: "2019-09-21",
  },
  {
    value: 371,
    day: "2021-10-24",
  },
  {
    value: 267,
    day: "2019-09-29",
  },
  {
    value: 350,
    day: "2020-08-30",
  },
  {
    value: 241,
    day: "2021-11-28",
  },
  {
    value: 171,
    day: "2019-05-13",
  },
  {
    value: 119,
    day: "2020-10-15",
  },
  {
    value: 380,
    day: "2021-12-28",
  },
  {
    value: 191,
    day: "2021-09-16",
  },
  {
    value: 263,
    day: "2020-07-24",
  },
  {
    value: 59,
    day: "2021-10-06",
  },
  {
    value: 218,
    day: "2021-10-08",
  },
  {
    value: 140,
    day: "2019-09-24",
  },
  {
    value: 149,
    day: "2021-06-22",
  },
  {
    value: 141,
    day: "2019-08-26",
  },
  {
    value: 226,
    day: "2020-12-01",
  },
  {
    value: 372,
    day: "2020-09-17",
  },
  {
    value: 58,
    day: "2021-07-30",
  },
  {
    value: 395,
    day: "2021-06-12",
  },
  {
    value: 366,
    day: "2020-06-14",
  },
  {
    value: 174,
    day: "2022-04-30",
  },
  {
    value: 233,
    day: "2020-08-07",
  },
  {
    value: 123,
    day: "2021-07-11",
  },
  {
    value: 167,
    day: "2021-08-24",
  },
  {
    value: 324,
    day: "2019-04-09",
  },
  {
    value: 147,
    day: "2021-01-14",
  },
  {
    value: 167,
    day: "2021-04-06",
  },
  {
    value: 385,
    day: "2022-06-10",
  },
  {
    value: 326,
    day: "2020-10-19",
  },
  {
    value: 58,
    day: "2019-09-02",
  },
  {
    value: 373,
    day: "2019-05-20",
  },
  {
    value: 79,
    day: "2020-07-29",
  },
  {
    value: 385,
    day: "2021-07-10",
  },
  {
    value: 96,
    day: "2021-11-23",
  },
  {
    value: 236,
    day: "2019-09-28",
  },
  {
    value: 265,
    day: "2022-06-08",
  },
  {
    value: 232,
    day: "2020-01-21",
  },
  {
    value: 370,
    day: "2019-11-17",
  },
  {
    value: 162,
    day: "2021-11-10",
  },
  {
    value: 327,
    day: "2021-04-26",
  },
  {
    value: 14,
    day: "2022-03-23",
  },
  {
    value: 362,
    day: "2021-09-01",
  },
  {
    value: 13,
    day: "2019-05-22",
  },
  {
    value: 2,
    day: "2020-05-16",
  },
  {
    value: 163,
    day: "2021-09-06",
  },
  {
    value: 327,
    day: "2020-12-10",
  },
  {
    value: 48,
    day: "2021-10-01",
  },
  {
    value: 242,
    day: "2019-09-17",
  },
  {
    value: 17,
    day: "2020-04-12",
  },
  {
    value: 246,
    day: "2020-10-25",
  },
  {
    value: 383,
    day: "2021-12-22",
  },
  {
    value: 394,
    day: "2019-12-28",
  },
  {
    value: 186,
    day: "2021-09-05",
  },
  {
    value: 62,
    day: "2021-03-11",
  },
  {
    value: 34,
    day: "2021-01-12",
  },
  {
    value: 182,
    day: "2020-07-15",
  },
  {
    value: 383,
    day: "2020-05-01",
  },
  {
    value: 104,
    day: "2020-08-14",
  },
  {
    value: 95,
    day: "2020-08-26",
  },
  {
    value: 199,
    day: "2020-05-30",
  },
  {
    value: 267,
    day: "2021-08-08",
  },
  {
    value: 197,
    day: "2020-01-04",
  },
  {
    value: 127,
    day: "2021-04-27",
  },
  {
    value: 184,
    day: "2019-08-19",
  },
  {
    value: 23,
    day: "2020-03-16",
  },
  {
    value: 201,
    day: "2020-06-21",
  },
  {
    value: 98,
    day: "2021-10-05",
  },
  {
    value: 184,
    day: "2021-05-21",
  },
  {
    value: 216,
    day: "2019-08-08",
  },
  {
    value: 36,
    day: "2021-10-30",
  },
  {
    value: 113,
    day: "2022-08-01",
  },
  {
    value: 273,
    day: "2021-03-07",
  },
  {
    value: 43,
    day: "2019-08-29",
  },
  {
    value: 337,
    day: "2022-07-12",
  },
  {
    value: 379,
    day: "2020-10-09",
  },
  {
    value: 279,
    day: "2019-11-09",
  },
  {
    value: 6,
    day: "2020-06-27",
  },
  {
    value: 137,
    day: "2020-11-05",
  },
  {
    value: 11,
    day: "2021-07-29",
  },
  {
    value: 385,
    day: "2020-01-25",
  },
  {
    value: 29,
    day: "2022-04-03",
  },
  {
    value: 238,
    day: "2020-11-07",
  },
  {
    value: 293,
    day: "2020-05-18",
  },
  {
    value: 69,
    day: "2019-11-08",
  },
  {
    value: 287,
    day: "2019-07-15",
  },
  {
    value: 271,
    day: "2022-06-21",
  },
  {
    value: 169,
    day: "2022-03-06",
  },
  {
    value: 114,
    day: "2021-06-14",
  },
  {
    value: 309,
    day: "2020-08-24",
  },
  {
    value: 147,
    day: "2021-12-26",
  },
  {
    value: 164,
    day: "2020-10-13",
  },
  {
    value: 226,
    day: "2021-04-08",
  },
  {
    value: 274,
    day: "2021-12-16",
  },
  {
    value: 314,
    day: "2019-07-13",
  },
  {
    value: 50,
    day: "2021-01-04",
  },
  {
    value: 272,
    day: "2021-12-29",
  },
  {
    value: 20,
    day: "2019-10-30",
  },
  {
    value: 376,
    day: "2020-02-25",
  },
  {
    value: 176,
    day: "2019-06-09",
  },
  {
    value: 266,
    day: "2021-02-02",
  },
  {
    value: 33,
    day: "2020-11-04",
  },
  {
    value: 4,
    day: "2020-12-27",
  },
  {
    value: 350,
    day: "2019-07-23",
  },
  {
    value: 7,
    day: "2020-03-06",
  },
  {
    value: 348,
    day: "2020-12-30",
  },
  {
    value: 16,
    day: "2019-08-06",
  },
  {
    value: 319,
    day: "2021-01-23",
  },
  {
    value: 394,
    day: "2022-07-23",
  },
  {
    value: 48,
    day: "2020-12-05",
  },
  {
    value: 29,
    day: "2019-12-30",
  },
  {
    value: 81,
    day: "2021-07-25",
  },
  {
    value: 200,
    day: "2021-05-16",
  },
  {
    value: 151,
    day: "2021-02-24",
  },
  {
    value: 207,
    day: "2020-05-27",
  },
  {
    value: 320,
    day: "2021-05-30",
  },
  {
    value: 111,
    day: "2019-10-04",
  },
  {
    value: 247,
    day: "2020-09-06",
  },
  {
    value: 5,
    day: "2022-05-13",
  },
  {
    value: 110,
    day: "2019-11-21",
  },
  {
    value: 231,
    day: "2021-03-18",
  },
  {
    value: 102,
    day: "2021-01-01",
  },
  {
    value: 159,
    day: "2022-02-22",
  },
  {
    value: 156,
    day: "2019-04-18",
  },
  {
    value: 381,
    day: "2021-02-01",
  },
  {
    value: 190,
    day: "2019-04-13",
  },
  {
    value: 178,
    day: "2021-02-04",
  },
  {
    value: 193,
    day: "2021-12-09",
  },
  {
    value: 55,
    day: "2020-01-02",
  },
  {
    value: 92,
    day: "2021-03-22",
  },
  {
    value: 200,
    day: "2021-06-02",
  },
  {
    value: 180,
    day: "2019-04-03",
  },
  {
    value: 370,
    day: "2022-04-26",
  },
  {
    value: 127,
    day: "2021-02-26",
  },
  {
    value: 141,
    day: "2019-04-19",
  },
  {
    value: 13,
    day: "2021-11-21",
  },
  {
    value: 376,
    day: "2021-04-04",
  },
  {
    value: 353,
    day: "2019-06-27",
  },
  {
    value: 247,
    day: "2020-01-29",
  },
  {
    value: 391,
    day: "2019-09-22",
  },
  {
    value: 299,
    day: "2022-01-28",
  },
  {
    value: 226,
    day: "2019-09-25",
  },
  {
    value: 46,
    day: "2020-04-24",
  },
  {
    value: 222,
    day: "2021-07-20",
  },
  {
    value: 336,
    day: "2021-09-24",
  },
  {
    value: 312,
    day: "2021-01-24",
  },
  {
    value: 380,
    day: "2021-01-06",
  },
  {
    value: 81,
    day: "2021-05-12",
  },
  {
    value: 266,
    day: "2020-07-08",
  },
  {
    value: 15,
    day: "2021-07-16",
  },
  {
    value: 72,
    day: "2019-08-16",
  },
  {
    value: 96,
    day: "2019-10-08",
  },
  {
    value: 357,
    day: "2019-08-12",
  },
  {
    value: 393,
    day: "2022-03-15",
  },
  {
    value: 101,
    day: "2021-05-18",
  },
  {
    value: 52,
    day: "2022-07-18",
  },
  {
    value: 348,
    day: "2019-11-29",
  },
  {
    value: 125,
    day: "2020-03-17",
  },
  {
    value: 93,
    day: "2020-03-19",
  },
  {
    value: 254,
    day: "2021-01-09",
  },
  {
    value: 57,
    day: "2021-03-20",
  },
  {
    value: 207,
    day: "2019-05-23",
  },
  {
    value: 165,
    day: "2019-05-01",
  },
  {
    value: 171,
    day: "2021-03-05",
  },
  {
    value: 20,
    day: "2020-03-11",
  },
  {
    value: 311,
    day: "2021-08-11",
  },
  {
    value: 56,
    day: "2021-03-03",
  },
  {
    value: 316,
    day: "2019-04-16",
  },
  {
    value: 81,
    day: "2020-12-23",
  },
  {
    value: 65,
    day: "2020-11-14",
  },
  {
    value: 54,
    day: "2020-02-29",
  },
  {
    value: 364,
    day: "2019-09-10",
  },
  {
    value: 65,
    day: "2020-09-14",
  },
  {
    value: 161,
    day: "2019-10-18",
  },
  {
    value: 145,
    day: "2020-10-07",
  },
  {
    value: 154,
    day: "2019-05-28",
  },
  {
    value: 266,
    day: "2019-12-26",
  },
  {
    value: 114,
    day: "2020-11-23",
  },
  {
    value: 398,
    day: "2019-09-04",
  },
  {
    value: 352,
    day: "2022-07-01",
  },
  {
    value: 270,
    day: "2022-04-13",
  },
  {
    value: 12,
    day: "2019-09-07",
  },
  {
    value: 349,
    day: "2021-01-31",
  },
  {
    value: 160,
    day: "2022-03-22",
  },
  {
    value: 270,
    day: "2021-10-21",
  },
  {
    value: 23,
    day: "2020-10-31",
  },
  {
    value: 42,
    day: "2020-03-12",
  },
  {
    value: 214,
    day: "2021-09-14",
  },
  {
    value: 366,
    day: "2020-02-09",
  },
  {
    value: 335,
    day: "2020-05-04",
  },
  {
    value: 202,
    day: "2019-10-13",
  },
  {
    value: 399,
    day: "2019-12-03",
  },
  {
    value: 211,
    day: "2022-06-04",
  },
  {
    value: 319,
    day: "2022-04-02",
  },
  {
    value: 211,
    day: "2021-02-15",
  },
  {
    value: 329,
    day: "2022-04-25",
  },
  {
    value: 318,
    day: "2020-01-08",
  },
  {
    value: 170,
    day: "2019-06-06",
  },
  {
    value: 392,
    day: "2020-09-21",
  },
  {
    value: 52,
    day: "2020-12-08",
  },
  {
    value: 307,
    day: "2019-04-20",
  },
  {
    value: 0,
    day: "2020-03-20",
  },
  {
    value: 65,
    day: "2019-04-21",
  },
  {
    value: 104,
    day: "2019-09-01",
  },
  {
    value: 329,
    day: "2019-10-27",
  },
  {
    value: 88,
    day: "2019-04-06",
  },
  {
    value: 236,
    day: "2019-05-14",
  },
  {
    value: 226,
    day: "2019-07-16",
  },
  {
    value: 251,
    day: "2020-10-21",
  },
  {
    value: 352,
    day: "2022-01-08",
  },
  {
    value: 87,
    day: "2020-03-21",
  },
  {
    value: 389,
    day: "2021-11-16",
  },
  {
    value: 213,
    day: "2019-07-09",
  },
  {
    value: 22,
    day: "2022-04-01",
  },
  {
    value: 74,
    day: "2022-01-10",
  },
  {
    value: 154,
    day: "2022-02-01",
  },
  {
    value: 315,
    day: "2022-02-15",
  },
  {
    value: 162,
    day: "2021-11-19",
  },
  {
    value: 373,
    day: "2020-06-05",
  },
  {
    value: 167,
    day: "2020-08-12",
  },
  {
    value: 245,
    day: "2021-10-19",
  },
  {
    value: 230,
    day: "2020-12-22",
  },
  {
    value: 225,
    day: "2022-04-17",
  },
  {
    value: 389,
    day: "2021-12-17",
  },
  {
    value: 228,
    day: "2019-06-23",
  },
  {
    value: 29,
    day: "2019-06-07",
  },
  {
    value: 132,
    day: "2022-08-08",
  },
  {
    value: 270,
    day: "2020-08-23",
  },
  {
    value: 97,
    day: "2022-02-10",
  },
  {
    value: 303,
    day: "2022-07-14",
  },
  {
    value: 133,
    day: "2022-03-05",
  },
  {
    value: 150,
    day: "2020-04-04",
  },
  {
    value: 187,
    day: "2021-05-06",
  },
  {
    value: 292,
    day: "2022-06-11",
  },
  {
    value: 69,
    day: "2021-04-28",
  },
  {
    value: 150,
    day: "2021-01-17",
  },
  {
    value: 335,
    day: "2022-06-15",
  },
  {
    value: 388,
    day: "2019-09-11",
  },
  {
    value: 350,
    day: "2022-06-19",
  },
  {
    value: 143,
    day: "2022-03-27",
  },
  {
    value: 244,
    day: "2020-11-20",
  },
  {
    value: 183,
    day: "2019-06-30",
  },
  {
    value: 241,
    day: "2019-12-19",
  },
  {
    value: 18,
    day: "2019-05-06",
  },
  {
    value: 199,
    day: "2021-10-22",
  },
  {
    value: 13,
    day: "2021-05-10",
  },
  {
    value: 175,
    day: "2022-04-14",
  },
  {
    value: 49,
    day: "2022-05-04",
  },
  {
    value: 120,
    day: "2021-05-01",
  },
  {
    value: 328,
    day: "2021-04-09",
  },
  {
    value: 298,
    day: "2021-07-19",
  },
  {
    value: 384,
    day: "2020-05-06",
  },
  {
    value: 366,
    day: "2020-05-15",
  },
  {
    value: 49,
    day: "2020-09-15",
  },
  {
    value: 68,
    day: "2022-02-26",
  },
  {
    value: 13,
    day: "2021-06-20",
  },
  {
    value: 51,
    day: "2021-05-05",
  },
  {
    value: 320,
    day: "2020-11-09",
  },
  {
    value: 53,
    day: "2020-11-12",
  },
  {
    value: 162,
    day: "2019-05-27",
  },
  {
    value: 199,
    day: "2020-01-18",
  },
  {
    value: 397,
    day: "2021-04-18",
  },
  {
    value: 350,
    day: "2019-04-28",
  },
  {
    value: 343,
    day: "2021-06-18",
  },
  {
    value: 360,
    day: "2020-11-06",
  },
  {
    value: 398,
    day: "2021-03-01",
  },
  {
    value: 253,
    day: "2021-04-20",
  },
  {
    value: 101,
    day: "2021-10-12",
  },
  {
    value: 166,
    day: "2019-11-04",
  },
  {
    value: 220,
    day: "2020-05-03",
  },
  {
    value: 11,
    day: "2021-07-31",
  },
  {
    value: 315,
    day: "2020-07-28",
  },
  {
    value: 201,
    day: "2019-11-01",
  },
  {
    value: 306,
    day: "2021-04-25",
  },
  {
    value: 4,
    day: "2019-12-17",
  },
  {
    value: 137,
    day: "2021-01-08",
  },
  {
    value: 26,
    day: "2020-12-15",
  },
  {
    value: 79,
    day: "2021-08-17",
  },
  {
    value: 3,
    day: "2020-01-13",
  },
  {
    value: 25,
    day: "2021-11-11",
  },
  {
    value: 374,
    day: "2020-02-23",
  },
  {
    value: 177,
    day: "2021-06-06",
  },
  {
    value: 90,
    day: "2021-10-23",
  },
  {
    value: 50,
    day: "2020-02-20",
  },
  {
    value: 292,
    day: "2020-05-13",
  },
  {
    value: 185,
    day: "2022-07-03",
  },
  {
    value: 320,
    day: "2022-02-21",
  },
  {
    value: 229,
    day: "2020-07-21",
  },
  {
    value: 77,
    day: "2019-08-09",
  },
  {
    value: 132,
    day: "2022-07-10",
  },
  {
    value: 287,
    day: "2021-02-06",
  },
  {
    value: 174,
    day: "2020-04-18",
  },
  {
    value: 349,
    day: "2021-03-08",
  },
  {
    value: 59,
    day: "2021-02-27",
  },
  {
    value: 131,
    day: "2021-03-26",
  },
  {
    value: 276,
    day: "2022-06-05",
  },
  {
    value: 201,
    day: "2019-12-21",
  },
  {
    value: 143,
    day: "2021-10-20",
  },
  {
    value: 237,
    day: "2019-11-27",
  },
  {
    value: 56,
    day: "2020-06-17",
  },
  {
    value: 379,
    day: "2019-06-22",
  },
  {
    value: 372,
    day: "2022-07-13",
  },
  {
    value: 398,
    day: "2022-01-14",
  },
  {
    value: 369,
    day: "2020-07-27",
  },
  {
    value: 220,
    day: "2020-01-12",
  },
  {
    value: 113,
    day: "2021-07-08",
  },
  {
    value: 28,
    day: "2020-04-22",
  },
  {
    value: 69,
    day: "2020-11-29",
  },
  {
    value: 322,
    day: "2020-12-28",
  },
  {
    value: 316,
    day: "2022-03-16",
  },
  {
    value: 343,
    day: "2022-05-12",
  },
  {
    value: 219,
    day: "2020-11-25",
  },
  {
    value: 285,
    day: "2019-12-08",
  },
  {
    value: 121,
    day: "2021-05-07",
  },
  {
    value: 6,
    day: "2020-02-13",
  },
  {
    value: 285,
    day: "2020-03-23",
  },
  {
    value: 204,
    day: "2022-05-21",
  },
  {
    value: 326,
    day: "2022-06-30",
  },
  {
    value: 13,
    day: "2022-07-17",
  },
  {
    value: 1,
    day: "2019-04-10",
  },
  {
    value: 101,
    day: "2021-10-25",
  },
  {
    value: 110,
    day: "2020-12-04",
  },
  {
    value: 166,
    day: "2019-10-15",
  },
  {
    value: 233,
    day: "2021-03-25",
  },
  {
    value: 177,
    day: "2019-07-22",
  },
  {
    value: 260,
    day: "2021-10-10",
  },
  {
    value: 21,
    day: "2019-10-05",
  },
  {
    value: 236,
    day: "2021-01-25",
  },
  {
    value: 346,
    day: "2020-07-05",
  },
  {
    value: 342,
    day: "2021-01-30",
  },
  {
    value: 53,
    day: "2021-11-20",
  },
  {
    value: 320,
    day: "2019-07-27",
  },
  {
    value: 276,
    day: "2022-01-29",
  },
  {
    value: 212,
    day: "2020-09-27",
  },
  {
    value: 146,
    day: "2022-01-23",
  },
  {
    value: 51,
    day: "2020-11-28",
  },
  {
    value: 302,
    day: "2022-01-18",
  },
  {
    value: 273,
    day: "2019-05-30",
  },
  {
    value: 324,
    day: "2021-06-08",
  },
  {
    value: 141,
    day: "2020-07-25",
  },
  {
    value: 256,
    day: "2020-10-11",
  },
  {
    value: 263,
    day: "2022-02-09",
  },
  {
    value: 56,
    day: "2022-03-20",
  },
  {
    value: 285,
    day: "2019-06-17",
  },
  {
    value: 316,
    day: "2021-07-03",
  },
  {
    value: 113,
    day: "2021-12-20",
  },
  {
    value: 171,
    day: "2019-05-03",
  },
  {
    value: 25,
    day: "2021-12-02",
  },
  {
    value: 114,
    day: "2021-09-10",
  },
  {
    value: 5,
    day: "2022-04-09",
  },
  {
    value: 368,
    day: "2020-06-12",
  },
  {
    value: 232,
    day: "2020-02-07",
  },
  {
    value: 216,
    day: "2022-06-27",
  },
  {
    value: 63,
    day: "2020-01-15",
  },
  {
    value: 59,
    day: "2020-07-03",
  },
  {
    value: 110,
    day: "2022-02-02",
  },
  {
    value: 5,
    day: "2021-08-13",
  },
  {
    value: 56,
    day: "2020-09-22",
  },
  {
    value: 337,
    day: "2020-03-18",
  },
  {
    value: 46,
    day: "2019-08-31",
  },
  {
    value: 28,
    day: "2019-08-18",
  },
  {
    value: 199,
    day: "2020-07-06",
  },
  {
    value: 77,
    day: "2020-01-30",
  },
  {
    value: 145,
    day: "2020-01-24",
  },
  {
    value: 323,
    day: "2021-10-02",
  },
  {
    value: 144,
    day: "2021-08-27",
  },
  {
    value: 51,
    day: "2020-04-06",
  },
  {
    value: 106,
    day: "2021-08-31",
  },
  {
    value: 212,
    day: "2021-08-15",
  },
  {
    value: 203,
    day: "2022-04-16",
  },
  {
    value: 288,
    day: "2019-09-19",
  },
  {
    value: 75,
    day: "2019-10-09",
  },
  {
    value: 87,
    day: "2021-09-27",
  },
  {
    value: 382,
    day: "2020-06-04",
  },
  {
    value: 130,
    day: "2020-04-07",
  },
  {
    value: 72,
    day: "2021-08-28",
  },
  {
    value: 137,
    day: "2021-08-14",
  },
  {
    value: 5,
    day: "2022-06-02",
  },
  {
    value: 112,
    day: "2019-09-09",
  },
  {
    value: 99,
    day: "2021-11-25",
  },
  {
    value: 385,
    day: "2021-08-22",
  },
  {
    value: 327,
    day: "2019-05-11",
  },
  {
    value: 178,
    day: "2020-01-16",
  },
  {
    value: 181,
    day: "2022-05-09",
  },
  {
    value: 194,
    day: "2020-03-25",
  },
  {
    value: 365,
    day: "2021-11-26",
  },
  {
    value: 183,
    day: "2021-07-05",
  },
  {
    value: 357,
    day: "2020-11-21",
  },
  {
    value: 216,
    day: "2020-01-17",
  },
  {
    value: 376,
    day: "2020-08-05",
  },
  {
    value: 69,
    day: "2020-12-31",
  },
  {
    value: 165,
    day: "2022-04-19",
  },
  {
    value: 222,
    day: "2019-10-10",
  },
  {
    value: 124,
    day: "2020-10-28",
  },
  {
    value: 226,
    day: "2020-05-05",
  },
  {
    value: 80,
    day: "2021-04-22",
  },
  {
    value: 345,
    day: "2019-12-11",
  },
  {
    value: 393,
    day: "2022-01-13",
  },
  {
    value: 56,
    day: "2021-09-09",
  },
  {
    value: 156,
    day: "2020-01-01",
  },
  {
    value: 206,
    day: "2021-05-17",
  },
  {
    value: 322,
    day: "2019-12-23",
  },
  {
    value: 307,
    day: "2022-06-14",
  },
  {
    value: 320,
    day: "2021-05-13",
  },
  {
    value: 96,
    day: "2022-03-19",
  },
  {
    value: 111,
    day: "2021-01-16",
  },
  {
    value: 111,
    day: "2021-02-13",
  },
  {
    value: 180,
    day: "2019-05-16",
  },
  {
    value: 354,
    day: "2019-12-16",
  },
  {
    value: 201,
    day: "2019-10-26",
  },
  {
    value: 318,
    day: "2020-04-19",
  },
  {
    value: 230,
    day: "2021-05-31",
  },
  {
    value: 43,
    day: "2020-09-10",
  },
  {
    value: 120,
    day: "2022-05-08",
  },
  {
    value: 392,
    day: "2020-07-10",
  },
  {
    value: 31,
    day: "2020-02-26",
  },
  {
    value: 270,
    day: "2021-09-13",
  },
  {
    value: 216,
    day: "2020-06-24",
  },
  {
    value: 10,
    day: "2019-07-07",
  },
  {
    value: 137,
    day: "2020-03-09",
  },
  {
    value: 68,
    day: "2021-06-16",
  },
  {
    value: 343,
    day: "2020-07-18",
  },
  {
    value: 382,
    day: "2021-11-17",
  },
  {
    value: 372,
    day: "2021-05-14",
  },
  {
    value: 127,
    day: "2019-11-11",
  },
  {
    value: 155,
    day: "2019-05-24",
  },
  {
    value: 45,
    day: "2021-10-04",
  },
  {
    value: 254,
    day: "2019-04-14",
  },
  {
    value: 113,
    day: "2020-08-25",
  },
  {
    value: 75,
    day: "2019-08-02",
  },
  {
    value: 53,
    day: "2021-05-09",
  },
  {
    value: 192,
    day: "2020-05-29",
  },
  {
    value: 176,
    day: "2021-03-15",
  },
  {
    value: 69,
    day: "2021-09-21",
  },
  {
    value: 69,
    day: "2020-02-12",
  },
  {
    value: 399,
    day: "2022-03-02",
  },
  {
    value: 229,
    day: "2020-03-01",
  },
  {
    value: 55,
    day: "2019-11-25",
  },
  {
    value: 58,
    day: "2022-07-11",
  },
  {
    value: 94,
    day: "2020-04-23",
  },
  {
    value: 375,
    day: "2019-10-29",
  },
  {
    value: 44,
    day: "2022-02-14",
  },
  {
    value: 281,
    day: "2021-05-27",
  },
  {
    value: 214,
    day: "2020-02-06",
  },
  {
    value: 351,
    day: "2019-08-13",
  },
  {
    value: 391,
    day: "2022-06-17",
  },
  {
    value: 43,
    day: "2019-11-06",
  },
  {
    value: 123,
    day: "2020-12-06",
  },
  {
    value: 47,
    day: "2020-05-08",
  },
  {
    value: 328,
    day: "2022-07-15",
  },
  {
    value: 88,
    day: "2020-05-07",
  },
  {
    value: 120,
    day: "2021-11-06",
  },
  {
    value: 40,
    day: "2019-11-10",
  },
  {
    value: 130,
    day: "2022-01-19",
  },
  {
    value: 218,
    day: "2022-05-05",
  },
  {
    value: 36,
    day: "2021-10-18",
  },
  {
    value: 136,
    day: "2019-07-30",
  },
  {
    value: 333,
    day: "2020-08-17",
  },
  {
    value: 55,
    day: "2019-08-28",
  },
  {
    value: 110,
    day: "2019-04-04",
  },
  {
    value: 358,
    day: "2020-11-17",
  },
  {
    value: 84,
    day: "2021-12-07",
  },
  {
    value: 247,
    day: "2020-05-25",
  },
  {
    value: 67,
    day: "2020-01-19",
  },
  {
    value: 16,
    day: "2019-09-13",
  },
  {
    value: 212,
    day: "2021-12-04",
  },
  {
    value: 152,
    day: "2020-10-18",
  },
  {
    value: 376,
    day: "2021-03-02",
  },
  {
    value: 125,
    day: "2020-06-23",
  },
  {
    value: 151,
    day: "2019-12-13",
  },
  {
    value: 286,
    day: "2020-03-03",
  },
  {
    value: 306,
    day: "2019-11-22",
  },
  {
    value: 256,
    day: "2021-04-21",
  },
  {
    value: 225,
    day: "2022-06-28",
  },
  {
    value: 150,
    day: "2021-07-22",
  },
  {
    value: 389,
    day: "2022-07-06",
  },
  {
    value: 238,
    day: "2019-04-01",
  },
  {
    value: 230,
    day: "2021-08-06",
  },
  {
    value: 360,
    day: "2019-05-18",
  },
  {
    value: 388,
    day: "2020-03-27",
  },
  {
    value: 239,
    day: "2020-09-29",
  },
  {
    value: 265,
    day: "2019-07-03",
  },
  {
    value: 295,
    day: "2021-07-09",
  },
  {
    value: 236,
    day: "2020-07-23",
  },
  {
    value: 121,
    day: "2022-02-04",
  },
  {
    value: 233,
    day: "2020-02-11",
  },
  {
    value: 98,
    day: "2022-02-11",
  },
  {
    value: 73,
    day: "2019-08-17",
  },
  {
    value: 198,
    day: "2019-10-24",
  },
  {
    value: 189,
    day: "2022-04-29",
  },
  {
    value: 90,
    day: "2022-05-28",
  },
  {
    value: 159,
    day: "2022-07-21",
  },
  {
    value: 274,
    day: "2021-09-02",
  },
  {
    value: 392,
    day: "2022-05-11",
  },
  {
    value: 310,
    day: "2022-02-18",
  },
  {
    value: 224,
    day: "2020-07-20",
  },
  {
    value: 146,
    day: "2020-05-20",
  },
  {
    value: 382,
    day: "2021-11-01",
  },
  {
    value: 74,
    day: "2022-03-28",
  },
  {
    value: 327,
    day: "2022-04-12",
  },
  {
    value: 312,
    day: "2020-11-01",
  },
  {
    value: 96,
    day: "2020-11-16",
  },
  {
    value: 47,
    day: "2020-05-26",
  },
  {
    value: 28,
    day: "2022-06-06",
  },
  {
    value: 119,
    day: "2022-01-25",
  },
  {
    value: 107,
    day: "2020-01-20",
  },
  {
    value: 67,
    day: "2022-02-24",
  },
  {
    value: 356,
    day: "2021-10-29",
  },
  {
    value: 102,
    day: "2019-05-25",
  },
  {
    value: 307,
    day: "2021-03-12",
  },
  {
    value: 184,
    day: "2019-06-10",
  },
  {
    value: 4,
    day: "2020-04-03",
  },
  {
    value: 315,
    day: "2020-01-06",
  },
  {
    value: 151,
    day: "2021-07-02",
  },
  {
    value: 300,
    day: "2021-08-26",
  },
  {
    value: 86,
    day: "2022-06-16",
  },
  {
    value: 132,
    day: "2019-10-25",
  },
  {
    value: 165,
    day: "2019-06-18",
  },
  {
    value: 351,
    day: "2019-06-25",
  },
  {
    value: 395,
    day: "2019-07-04",
  },
  {
    value: 213,
    day: "2022-05-16",
  },
  {
    value: 398,
    day: "2020-01-10",
  },
  {
    value: 25,
    day: "2020-05-12",
  },
  {
    value: 215,
    day: "2022-05-31",
  },
  {
    value: 66,
    day: "2019-04-07",
  },
  {
    value: 316,
    day: "2019-11-02",
  },
  {
    value: 51,
    day: "2019-11-30",
  },
  {
    value: 127,
    day: "2019-07-20",
  },
  {
    value: 283,
    day: "2020-10-10",
  },
  {
    value: 390,
    day: "2021-05-20",
  },
  {
    value: 239,
    day: "2019-09-30",
  },
  {
    value: 226,
    day: "2019-05-09",
  },
  {
    value: 240,
    day: "2021-06-23",
  },
  {
    value: 193,
    day: "2019-09-26",
  },
  {
    value: 30,
    day: "2021-09-26",
  },
  {
    value: 199,
    day: "2020-12-17",
  },
  {
    value: 172,
    day: "2020-06-13",
  },
  {
    value: 264,
    day: "2020-12-02",
  },
  {
    value: 361,
    day: "2020-07-07",
  },
  {
    value: 67,
    day: "2022-05-20",
  },
  {
    value: 276,
    day: "2020-07-01",
  },
  {
    value: 87,
    day: "2019-11-26",
  },
  {
    value: 316,
    day: "2022-08-11",
  },
  {
    value: 346,
    day: "2020-06-30",
  },
  {
    value: 227,
    day: "2022-01-04",
  },
  {
    value: 364,
    day: "2022-01-07",
  },
  {
    value: 194,
    day: "2021-03-21",
  },
  {
    value: 194,
    day: "2021-11-04",
  },
  {
    value: 57,
    day: "2020-03-14",
  },
  {
    value: 315,
    day: "2021-02-16",
  },
  {
    value: 207,
    day: "2022-05-30",
  },
  {
    value: 32,
    day: "2019-05-10",
  },
  {
    value: 336,
    day: "2020-06-26",
  },
  {
    value: 226,
    day: "2020-01-09",
  },
  {
    value: 20,
    day: "2022-05-15",
  },
  {
    value: 249,
    day: "2021-01-10",
  },
  {
    value: 13,
    day: "2019-05-12",
  },
  {
    value: 345,
    day: "2019-07-29",
  },
  {
    value: 60,
    day: "2019-11-12",
  },
  {
    value: 328,
    day: "2022-03-07",
  },
  {
    value: 218,
    day: "2019-07-12",
  },
  {
    value: 168,
    day: "2019-06-20",
  },
  {
    value: 13,
    day: "2020-02-18",
  },
  {
    value: 351,
    day: "2019-10-02",
  },
  {
    value: 308,
    day: "2022-08-06",
  },
  {
    value: 210,
    day: "2019-06-13",
  },
  {
    value: 39,
    day: "2022-04-05",
  },
  {
    value: 24,
    day: "2021-03-10",
  },
  {
    value: 145,
    day: "2021-06-03",
  },
  {
    value: 262,
    day: "2022-08-10",
  },
  {
    value: 76,
    day: "2020-09-13",
  },
  {
    value: 106,
    day: "2020-03-15",
  },
  {
    value: 364,
    day: "2020-07-02",
  },
  {
    value: 2,
    day: "2021-07-17",
  },
  {
    value: 86,
    day: "2021-07-06",
  },
  {
    value: 228,
    day: "2021-02-25",
  },
  {
    value: 134,
    day: "2020-10-04",
  },
  {
    value: 105,
    day: "2019-12-12",
  },
  {
    value: 305,
    day: "2021-09-19",
  },
  {
    value: 189,
    day: "2019-12-09",
  },
  {
    value: 386,
    day: "2019-12-04",
  },
  {
    value: 46,
    day: "2019-06-26",
  },
  {
    value: 97,
    day: "2022-06-22",
  },
  {
    value: 392,
    day: "2021-01-22",
  },
  {
    value: 240,
    day: "2019-08-30",
  },
  {
    value: 269,
    day: "2022-01-06",
  },
  {
    value: 368,
    day: "2022-06-23",
  },
  {
    value: 76,
    day: "2021-01-29",
  },
  {
    value: 331,
    day: "2019-07-28",
  },
  {
    value: 306,
    day: "2022-01-17",
  },
  {
    value: 111,
    day: "2021-09-20",
  },
  {
    value: 88,
    day: "2022-01-03",
  },
  {
    value: 265,
    day: "2020-08-03",
  },
  {
    value: 211,
    day: "2020-09-07",
  },
  {
    value: 277,
    day: "2021-06-30",
  },
  {
    value: 88,
    day: "2022-06-07",
  },
  {
    value: 44,
    day: "2021-04-16",
  },
  {
    value: 218,
    day: "2022-05-07",
  },
  {
    value: 68,
    day: "2022-03-13",
  },
  {
    value: 267,
    day: "2021-04-29",
  },
  {
    value: 313,
    day: "2022-06-03",
  },
  {
    value: 339,
    day: "2021-08-23",
  },
  {
    value: 305,
    day: "2019-12-20",
  },
  {
    value: 188,
    day: "2020-03-13",
  },
  {
    value: 173,
    day: "2020-10-01",
  },
  {
    value: 238,
    day: "2021-08-03",
  },
  {
    value: 28,
    day: "2021-06-21",
  },
  {
    value: 17,
    day: "2020-04-30",
  },
  {
    value: 292,
    day: "2019-08-10",
  },
  {
    value: 300,
    day: "2020-10-17",
  },
  {
    value: 363,
    day: "2020-10-14",
  },
  {
    value: 307,
    day: "2022-07-31",
  },
  {
    value: 285,
    day: "2019-08-01",
  },
  {
    value: 124,
    day: "2020-03-31",
  },
  {
    value: 160,
    day: "2021-09-18",
  },
  {
    value: 41,
    day: "2021-02-03",
  },
  {
    value: 163,
    day: "2022-07-16",
  },
  {
    value: 261,
    day: "2021-03-24",
  },
  {
    value: 211,
    day: "2020-10-30",
  },
  {
    value: 69,
    day: "2022-07-05",
  },
  {
    value: 20,
    day: "2021-01-21",
  },
  {
    value: 314,
    day: "2020-03-10",
  },
  {
    value: 356,
    day: "2021-06-29",
  },
  {
    value: 105,
    day: "2020-07-30",
  },
  {
    value: 213,
    day: "2019-06-03",
  },
  {
    value: 350,
    day: "2021-07-26",
  },
  {
    value: 356,
    day: "2019-08-27",
  },
  {
    value: 264,
    day: "2019-10-12",
  },
  {
    value: 21,
    day: "2022-04-08",
  },
  {
    value: 2,
    day: "2019-05-17",
  },
  {
    value: 275,
    day: "2020-07-11",
  },
  {
    value: 170,
    day: "2022-02-05",
  },
  {
    value: 275,
    day: "2021-11-05",
  },
  {
    value: 221,
    day: "2021-03-31",
  },
  {
    value: 148,
    day: "2020-12-18",
  },
  {
    value: 360,
    day: "2021-03-09",
  },
  {
    value: 3,
    day: "2022-05-25",
  },
  {
    value: 308,
    day: "2020-11-10",
  },
  {
    value: 269,
    day: "2021-04-14",
  },
  {
    value: 147,
    day: "2019-08-22",
  },
  {
    value: 12,
    day: "2021-10-17",
  },
  {
    value: 170,
    day: "2019-09-23",
  },
  {
    value: 360,
    day: "2021-03-16",
  },
  {
    value: 333,
    day: "2019-06-24",
  },
  {
    value: 55,
    day: "2019-06-19",
  },
  {
    value: 82,
    day: "2021-02-22",
  },
  {
    value: 84,
    day: "2021-07-21",
  },
  {
    value: 200,
    day: "2019-11-15",
  },
  {
    value: 176,
    day: "2021-11-07",
  },
  {
    value: 290,
    day: "2019-08-20",
  },
  {
    value: 18,
    day: "2020-07-14",
  },
  {
    value: 7,
    day: "2022-06-26",
  },
  {
    value: 304,
    day: "2020-07-12",
  },
  {
    value: 7,
    day: "2019-06-08",
  },
  {
    value: 216,
    day: "2021-12-14",
  },
  {
    value: 200,
    day: "2021-02-10",
  },
  {
    value: 29,
    day: "2021-03-14",
  },
  {
    value: 142,
    day: "2021-05-26",
  },
  {
    value: 297,
    day: "2021-08-25",
  },
  {
    value: 161,
    day: "2020-11-13",
  },
  {
    value: 44,
    day: "2019-04-02",
  },
  {
    value: 236,
    day: "2019-08-21",
  },
  {
    value: 167,
    day: "2021-08-30",
  },
  {
    value: 236,
    day: "2020-09-08",
  },
  {
    value: 7,
    day: "2020-09-09",
  },
  {
    value: 101,
    day: "2020-04-08",
  },
  {
    value: 348,
    day: "2021-06-11",
  },
  {
    value: 392,
    day: "2020-09-01",
  },
  {
    value: 261,
    day: "2019-07-01",
  },
  {
    value: 89,
    day: "2021-02-21",
  },
  {
    value: 353,
    day: "2019-05-26",
  },
  {
    value: 98,
    day: "2019-11-13",
  },
  {
    value: 156,
    day: "2021-10-07",
  },
  {
    value: 399,
    day: "2022-02-25",
  },
  {
    value: 343,
    day: "2020-06-10",
  },
  {
    value: 133,
    day: "2020-09-20",
  },
  {
    value: 91,
    day: "2022-08-07",
  },
  {
    value: 80,
    day: "2021-05-03",
  },
  {
    value: 371,
    day: "2019-04-29",
  },
  {
    value: 379,
    day: "2021-07-24",
  },
  {
    value: 270,
    day: "2019-09-15",
  },
  {
    value: 72,
    day: "2021-11-14",
  },
  {
    value: 282,
    day: "2020-09-05",
  },
  {
    value: 300,
    day: "2021-12-19",
  },
  {
    value: 23,
    day: "2020-02-14",
  },
  {
    value: 382,
    day: "2022-05-22",
  },
  {
    value: 16,
    day: "2021-12-11",
  },
  {
    value: 290,
    day: "2020-11-24",
  },
  {
    value: 219,
    day: "2022-02-16",
  },
  {
    value: 178,
    day: "2019-12-07",
  },
  {
    value: 177,
    day: "2019-12-25",
  },
  {
    value: 83,
    day: "2021-04-10",
  },
  {
    value: 41,
    day: "2019-07-21",
  },
  {
    value: 318,
    day: "2020-09-24",
  },
  {
    value: 389,
    day: "2021-06-09",
  },
  {
    value: 78,
    day: "2020-08-11",
  },
  {
    value: 291,
    day: "2022-02-06",
  },
  {
    value: 75,
    day: "2019-08-03",
  },
  {
    value: 389,
    day: "2019-11-19",
  },
  {
    value: 3,
    day: "2020-12-11",
  },
  {
    value: 111,
    day: "2021-04-01",
  },
  {
    value: 202,
    day: "2020-03-08",
  },
  {
    value: 177,
    day: "2021-12-24",
  },
  {
    value: 92,
    day: "2020-11-19",
  },
  {
    value: 260,
    day: "2021-09-17",
  },
  {
    value: 55,
    day: "2019-06-28",
  },
  {
    value: 328,
    day: "2020-09-23",
  },
  {
    value: 9,
    day: "2021-12-06",
  },
  {
    value: 218,
    day: "2020-05-21",
  },
  {
    value: 157,
    day: "2020-06-28",
  },
  {
    value: 118,
    day: "2021-02-28",
  },
  {
    value: 57,
    day: "2020-08-15",
  },
  {
    value: 214,
    day: "2020-05-17",
  },
  {
    value: 244,
    day: "2022-01-11",
  },
  {
    value: 382,
    day: "2019-07-31",
  },
  {
    value: 265,
    day: "2021-08-29",
  },
  {
    value: 399,
    day: "2020-09-04",
  },
  {
    value: 164,
    day: "2021-08-02",
  },
  {
    value: 222,
    day: "2019-07-02",
  },
  {
    value: 162,
    day: "2021-06-17",
  },
  {
    value: 354,
    day: "2019-04-30",
  },
  {
    value: 235,
    day: "2022-02-28",
  },
  {
    value: 273,
    day: "2020-06-18",
  },
  {
    value: 210,
    day: "2019-04-08",
  },
  {
    value: 364,
    day: "2020-07-09",
  },
  {
    value: 46,
    day: "2020-05-24",
  },
  {
    value: 291,
    day: "2021-05-19",
  },
  {
    value: 251,
    day: "2022-07-04",
  },
  {
    value: 328,
    day: "2019-05-15",
  },
  {
    value: 217,
    day: "2021-03-28",
  },
  {
    value: 375,
    day: "2020-08-10",
  },
  {
    value: 371,
    day: "2022-02-17",
  },
  {
    value: 395,
    day: "2022-02-07",
  },
  {
    value: 226,
    day: "2020-02-27",
  },
  {
    value: 186,
    day: "2020-06-06",
  },
  {
    value: 324,
    day: "2020-02-16",
  },
  {
    value: 82,
    day: "2022-03-24",
  },
  {
    value: 229,
    day: "2020-05-11",
  },
  {
    value: 202,
    day: "2020-10-27",
  },
  {
    value: 341,
    day: "2021-05-25",
  },
  {
    value: 162,
    day: "2022-07-08",
  },
  {
    value: 129,
    day: "2020-11-11",
  },
  {
    value: 275,
    day: "2022-05-24",
  },
  {
    value: 34,
    day: "2021-03-06",
  },
  {
    value: 133,
    day: "2022-01-20",
  },
  {
    value: 187,
    day: "2022-06-29",
  },
  {
    value: 378,
    day: "2020-06-29",
  },
  {
    value: 297,
    day: "2019-10-11",
  },
  {
    value: 282,
    day: "2020-10-23",
  },
  {
    value: 367,
    day: "2020-09-16",
  },
  {
    value: 272,
    day: "2020-12-21",
  },
  {
    value: 229,
    day: "2020-03-28",
  },
  {
    value: 58,
    day: "2020-10-05",
  },
  {
    value: 118,
    day: "2020-08-27",
  },
  {
    value: 283,
    day: "2021-10-09",
  },
  {
    value: 92,
    day: "2021-11-15",
  },
  {
    value: 101,
    day: "2020-07-04",
  },
  {
    value: 400,
    day: "2019-05-05",
  },
  {
    value: 127,
    day: "2020-10-16",
  },
  {
    value: 273,
    day: "2021-05-22",
  },
  {
    value: 395,
    day: "2020-08-31",
  },
  {
    value: 229,
    day: "2020-11-08",
  },
  {
    value: 214,
    day: "2019-09-12",
  },
  {
    value: 199,
    day: "2020-12-12",
  },
  {
    value: 323,
    day: "2022-06-20",
  },
  {
    value: 348,
    day: "2020-06-20",
  },
  {
    value: 23,
    day: "2020-08-18",
  },
  {
    value: 12,
    day: "2019-07-18",
  },
  {
    value: 30,
    day: "2019-12-15",
  },
  {
    value: 291,
    day: "2020-07-22",
  },
  {
    value: 272,
    day: "2021-08-10",
  },
  {
    value: 205,
    day: "2021-10-11",
  },
  {
    value: 94,
    day: "2019-04-22",
  },
  {
    value: 55,
    day: "2019-12-02",
  },
  {
    value: 60,
    day: "2019-06-14",
  },
  {
    value: 322,
    day: "2022-01-24",
  },
  {
    value: 262,
    day: "2021-04-12",
  },
  {
    value: 334,
    day: "2020-04-02",
  },
  {
    value: 99,
    day: "2021-11-02",
  },
  {
    value: 35,
    day: "2021-02-09",
  },
  {
    value: 321,
    day: "2021-01-26",
  },
  {
    value: 49,
    day: "2020-02-19",
  },
  {
    value: 138,
    day: "2021-08-09",
  },
  {
    value: 10,
    day: "2019-11-16",
  },
  {
    value: 24,
    day: "2019-04-25",
  },
  {
    value: 128,
    day: "2021-01-15",
  },
  {
    value: 214,
    day: "2021-09-08",
  },
  {
    value: 207,
    day: "2021-11-03",
  },
  {
    value: 76,
    day: "2021-01-19",
  },
  {
    value: 64,
    day: "2019-10-03",
  },
  {
    value: 291,
    day: "2020-09-28",
  },
  {
    value: 283,
    day: "2022-05-18",
  },
  {
    value: 349,
    day: "2020-09-19",
  },
  {
    value: 43,
    day: "2020-11-26",
  },
  {
    value: 155,
    day: "2021-07-18",
  },
  {
    value: 187,
    day: "2021-02-20",
  },
  {
    value: 140,
    day: "2020-09-03",
  },
  {
    value: 28,
    day: "2022-03-26",
  },
  {
    value: 270,
    day: "2020-08-21",
  },
  {
    value: 233,
    day: "2021-11-08",
  },
];

const lineData = [
  {
    id: "japan",
    color: "hsl(226, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 21,
      },
      {
        x: "helicopter",
        y: 161,
      },
      {
        x: "boat",
        y: 44,
      },
      {
        x: "train",
        y: 118,
      },
      {
        x: "subway",
        y: 51,
      },
      {
        x: "bus",
        y: 239,
      },
      {
        x: "car",
        y: 159,
      },
      {
        x: "moto",
        y: 179,
      },
      {
        x: "bicycle",
        y: 222,
      },
      {
        x: "horse",
        y: 108,
      },
      {
        x: "skateboard",
        y: 65,
      },
      {
        x: "others",
        y: 224,
      },
    ],
  },
  {
    id: "france",
    color: "hsl(59, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 241,
      },
      {
        x: "helicopter",
        y: 27,
      },
      {
        x: "boat",
        y: 216,
      },
      {
        x: "train",
        y: 245,
      },
      {
        x: "subway",
        y: 87,
      },
      {
        x: "bus",
        y: 207,
      },
      {
        x: "car",
        y: 8,
      },
      {
        x: "moto",
        y: 79,
      },
      {
        x: "bicycle",
        y: 266,
      },
      {
        x: "horse",
        y: 63,
      },
      {
        x: "skateboard",
        y: 173,
      },
      {
        x: "others",
        y: 18,
      },
    ],
  },
  {
    id: "us",
    color: "hsl(212, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 34,
      },
      {
        x: "helicopter",
        y: 239,
      },
      {
        x: "boat",
        y: 271,
      },
      {
        x: "train",
        y: 199,
      },
      {
        x: "subway",
        y: 10,
      },
      {
        x: "bus",
        y: 113,
      },
      {
        x: "car",
        y: 58,
      },
      {
        x: "moto",
        y: 259,
      },
      {
        x: "bicycle",
        y: 276,
      },
      {
        x: "horse",
        y: 187,
      },
      {
        x: "skateboard",
        y: 243,
      },
      {
        x: "others",
        y: 64,
      },
    ],
  },
  {
    id: "germany",
    color: "hsl(278, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 140,
      },
      {
        x: "helicopter",
        y: 72,
      },
      {
        x: "boat",
        y: 242,
      },
      {
        x: "train",
        y: 86,
      },
      {
        x: "subway",
        y: 84,
      },
      {
        x: "bus",
        y: 163,
      },
      {
        x: "car",
        y: 238,
      },
      {
        x: "moto",
        y: 144,
      },
      {
        x: "bicycle",
        y: 90,
      },
      {
        x: "horse",
        y: 96,
      },
      {
        x: "skateboard",
        y: 131,
      },
      {
        x: "others",
        y: 11,
      },
    ],
  },
  {
    id: "norway",
    color: "hsl(156, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 145,
      },
      {
        x: "helicopter",
        y: 217,
      },
      {
        x: "boat",
        y: 212,
      },
      {
        x: "train",
        y: 187,
      },
      {
        x: "subway",
        y: 54,
      },
      {
        x: "bus",
        y: 173,
      },
      {
        x: "car",
        y: 188,
      },
      {
        x: "moto",
        y: 6,
      },
      {
        x: "bicycle",
        y: 76,
      },
      {
        x: "horse",
        y: 130,
      },
      {
        x: "skateboard",
        y: 166,
      },
      {
        x: "others",
        y: 93,
      },
    ],
  },
];

const pieData = [
  {
    id: "healthcert",
    label: "healthcert",
    value: 490,
    color: "hsl(166, 70%, 50%)",
  },
  {
    id: "php",
    label: "php",
    value: 365,
    color: "hsl(360, 70%, 50%)",
  },
  {
    id: "erlang",
    label: "erlang",
    value: 480,
    color: "hsl(43, 70%, 50%)",
  },
  {
    id: "elixir",
    label: "elixir",
    value: 535,
    color: "hsl(209, 70%, 50%)",
  },
  {
    id: "eudcc",
    label: "eudcc",
    value: 593,
    color: "hsl(239, 70%, 50%)",
  },
];
