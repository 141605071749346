/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

export interface PageLink {
  title: string;
  path: string;
}

export interface PageDataContextModel {
  pageTitle?: string;
  setPageTitle: (_title: string) => void;
  pageDescription?: string;
  setPageDescription: (_description: string) => void;
  pageBreadcrumbs?: Array<PageLink>;
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void;
  pageActions?: React.ReactElement;
  setPageActions: (_actions: React.ReactElement | undefined) => void;
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
  setPageActions: (_actions: React.ReactElement | undefined) => {},
});

const PageDataProvider: React.FC = ({ children }) => {
  const [pageTitle, setPageTitle] = useState<string>('');
  const [pageDescription, setPageDescription] = useState<string>('');
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([]);
  const [pageActions, setPageActions] = useState<
    React.ReactElement | undefined
  >(undefined);
  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    pageActions,
    setPageActions,
  };
  return (
    <PageDataContext.Provider value={value}>
      {children}
    </PageDataContext.Provider>
  );
};

function usePageData() {
  return useContext(PageDataContext);
}

type Props = {
  description?: string;
  breadcrumbs?: Array<PageLink>;
  actions?: React.ReactElement;
};

const PageTitle: FC<Props> = ({
  children,
  description,
  breadcrumbs,
  actions,
}) => {
  const {
    setPageTitle,
    setPageDescription,
    setPageBreadcrumbs,
    setPageActions,
  } = usePageData();
  useEffect(() => {
    if (children) {
      setPageTitle(children.toString());
    }
    return () => {
      setPageTitle('');
    };
  }, [children]);

  useEffect(() => {
    if (description) {
      setPageDescription(description);
    }
    return () => {
      setPageDescription('');
    };
  }, [description]);

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs);
    }
    return () => {
      setPageBreadcrumbs([]);
    };
  }, [breadcrumbs]);

  useEffect(() => {
    if (actions) {
      setPageActions(actions);
    }
    return () => {
      setPageActions(undefined);
    };
  }, [actions]);

  return <></>;
};

const PageDescription: React.FC = ({ children }) => {
  const { setPageDescription } = usePageData();
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString());
    }
    return () => {
      setPageDescription('');
    };
  }, [children]);
  return <></>;
};

const PageActions: React.FC<{ actions?: React.ReactElement }> = ({
  actions,
}) => {
  const { setPageActions } = usePageData();
  useEffect(() => {
    if (actions) {
      setPageActions(actions);
    }
    return () => {
      setPageActions(undefined);
    };
  }, [actions]);
  return <></>;
};

export {
  PageDescription,
  PageTitle,
  PageDataProvider,
  PageActions,
  usePageData,
};
