import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CurrentUser {
  id: string;
  name: string;
  email: string;
  role: {
    id: string;
    name: string;
    permissions: { id: string; name: string }[];
  };
}

export interface AuthStore {
  currentUser: CurrentUser | null;
  token: string | null;
}

const slice = createSlice({
  name: "auth",
  initialState: {
    currentUser: null,
    token: null,
  } as AuthStore,
  reducers: {
    authReceived: (auth, action: PayloadAction<CurrentUser>) => {
      auth.currentUser = action.payload;
    },
    tokenReceived: (auth, action) => {
      auth.token = action.payload;
    },
    authReset: (auth, action: PayloadAction<null>) => {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");

      auth.token = null;
      auth.currentUser = null;
    },
  },
});

export const { authReceived, tokenReceived, authReset } = slice.actions;
export const { reducer: authReducer } = slice;
