import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Box, Group } from "@mantine/core";
import { useNotifications } from "@mantine/notifications";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { Cell, Column } from "react-table";
import { FilterBadge } from "../../../components/FilterBadge";
import {
  DataType,
  FilterInputOption,
  FilterValue,
  InputType,
  QueryType,
} from "../../../components/FilterInput";
import { FilterMenu } from "../../../components/FilterMenu";
import Table from "../../../components/Table";
import { PageTitle } from "../../../layouts/master/core";
import {
  removeFilterFromFilterList,
  transformFiltersToQueryVariables,
} from "../../../utils/filter";
import {
  calculatePageCount,
  handlePageChanged,
  handlePageSizeChanged,
} from "../../../utils/pagination";

const filterOptions: FilterInputOption[] = [
  {
    label: "Name",
    accessor: "name",
    input: InputType.TEXT,
    queries: [QueryType.CONTAINS, QueryType.EQUALS],
    type: DataType.STRING,
  },
];

interface MockQueryVariablesType {
  take: number;
  skip: number;
  where: Record<string, any>;
}

interface MockQueryDataType {
  items: {
    id: string;
    name: string;
    email: string;
    meta: { type: string };
    createdAt: Date;
  }[];
  pagination: {
    totalCount: number;
    skip: number;
    take: number;
  };
}

const mockData: MockQueryDataType = {
  items: [
    {
      id: "1234",
      name: "John Doe",
      email: "dev@pow.asia",
      meta: { type: "User" },
      createdAt: new Date(),
    },
    {
      id: "1234",
      name: "John Doe",
      email: "dev@pow.asia",
      meta: { type: "User" },
      createdAt: new Date(),
    },
    {
      id: "1234",
      name: "John Doe",
      email: "dev@pow.asia",
      meta: { type: "User" },
      createdAt: new Date(),
    },
    {
      id: "1234",
      name: "John Doe",
      email: "dev@pow.asia",
      meta: { type: "User" },
      createdAt: new Date(),
    },
    {
      id: "1234",
      name: "John Doe",
      email: "dev@pow.asia",
      meta: { type: "User" },
      createdAt: new Date(),
    },
  ],
  pagination: {
    totalCount: 5,
    skip: 0,
    take: 25,
  },
};

const TablePage = () => {
  const notifications = useNotifications();
  const [filterMenuOpened, setFilterMenuOpened] = useState(false);
  const [filters, setFilters] = useState<FilterValue[]>([]);
  const [queryVariables, setQueryVariables] = useState<MockQueryVariablesType>({
    take: 25,
    skip: 0,
    where: {},
  });
  const [data, setData] = useState<MockQueryDataType>(mockData);

  useEffect(() => {
    setQueryVariables((prev) => ({
      ...prev,
      where: transformFiltersToQueryVariables(filters),
    }));
  }, [filters]);

  const tableColumns = useMemo<Column[]>(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Type",
        accessor: "meta.type",
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        Cell: ({ value }: Cell) => dayjs(value).format("DD MMM YYYY"),
      },
      {
        Header: "Actions",
        id: "actions",
        Cell: ({ row: { original } }: Cell<any>) => (
          <Box>
            <ActionIcon color="yellow" onClick={() => {}}>
              <FontAwesomeIcon icon={faEdit} />
            </ActionIcon>
          </Box>
        ),
      },
    ],
    []
  );

  return (
    <Box pr="xl" py="xl">
      <PageTitle
        actions={
          <Group sx={{ flex: "1" }} position="right">
            <FilterMenu
              opened={filterMenuOpened}
              onClose={() => setFilterMenuOpened(false)}
              onOpen={() => setFilterMenuOpened(true)}
              onSubmit={(value) => setFilters((prev) => [...prev, value])}
              options={filterOptions}
            />
          </Group>
        }
      >
        Table Page
      </PageTitle>
      <Box mb="xl">
        {filters.map((filter, index) => (
          <FilterBadge
            key={index}
            onRemove={(filter) =>
              setFilters((prev) => removeFilterFromFilterList(filter, prev))
            }
            value={filter}
          />
        ))}
      </Box>
      <Table
        columns={tableColumns}
        pageSize={25}
        onPageSizeChanged={handlePageSizeChanged(setQueryVariables)}
        onPageChanged={handlePageChanged(setQueryVariables)}
        data={data?.items || []}
        pageCount={
          data
            ? calculatePageCount(
                data.pagination.totalCount,
                data.pagination.take
              )
            : 1
        }
        mantineTableProps={{ striped: true }}
      />
    </Box>
  );
};

export { TablePage };
