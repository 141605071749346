import { Navigate, Outlet } from "react-router-dom";

const AuthPage = ({ isAuthorized }: { isAuthorized: boolean }) => {
  if (isAuthorized) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export { AuthPage };
