import {
  Box,
  Button,
  Group,
  Menu,
  Select,
  SelectItem,
  Text,
  TextInput,
} from "@mantine/core";
import { useState } from "react";
import { BlankIcon } from "./icons/Blank";
import { ContainsIcon } from "./icons/Contains";
import { EqualsIcon } from "./icons/Equals";

enum InputType {
  TEXT = "text",
  SELECT = "selecf",
}

enum QueryType {
  CONTAINS = "contains",
  EQUALS = "equals",
}

enum DataType {
  STRING = "string",
  NUMBER = "number",
  BOOLEAN = "boolean",
  DATE = "date",
}

export interface FilterInputOption {
  label: string;
  accessor: string;
  input: InputType;
  options?: SelectItem[];
  queries: QueryType[];
  type: DataType;
}

export interface FilterValue {
  accessor: string;
  query: QueryType;
  value: string;
  type: DataType;
}

interface FilterInputProps {
  options: FilterInputOption[];
  onSubmit: (value: FilterValue) => void;
}

const getQueryIcon = (type?: QueryType) => {
  const style = {
    fill: "#768191",
    width: "16px",
    height: "16px",
  };

  switch (type) {
    case QueryType.CONTAINS: {
      return <ContainsIcon style={style} />;
    }
    case QueryType.EQUALS: {
      return <EqualsIcon style={style} />;
    }
    default:
      return <BlankIcon style={style} />;
  }
};

const FilterInput = ({ options, onSubmit }: FilterInputProps) => {
  const [selectedOption, setSelectedOption] = useState<FilterInputOption>();
  const [value, setValue] = useState("");
  const [query, setQuery] = useState<QueryType>();

  return (
    <Group>
      <Select
        data={options.map(({ label, accessor }) => ({
          label,
          value: accessor,
        }))}
        onChange={(val) => {
          const option = options.find((item) => item.accessor === val);

          setSelectedOption(option);
          setQuery(option?.queries[0]);
        }}
        withinPortal={false}
        size="xs"
        styles={{ root: { flex: "0 0 150px" } }}
        placeholder="Select"
      />
      <Menu
        withinPortal={false}
        placement="center"
        control={
          <Button
            size="xs"
            styles={{ root: { width: "30px", padding: "0" } }}
            disabled={!selectedOption}
            variant="light"
          >
            {getQueryIcon(query)}
          </Button>
        }
      >
        {selectedOption?.queries.map((query) => (
          <Menu.Item onClick={() => setQuery(query)}>
            <Group align="center">
              <Box>{getQueryIcon(query)}</Box>
              <Text size="xs">{query}</Text>
            </Group>
          </Menu.Item>
        ))}
      </Menu>
      {selectedOption ? (
        <>
          {selectedOption.input === InputType.TEXT && (
            <TextInput
              placeholder="Enter value"
              size="xs"
              value={value}
              onChange={(event) => setValue(event.currentTarget.value)}
              styles={{ root: { flex: "0 0 150px" } }}
            />
          )}
          {selectedOption.input === InputType.SELECT && (
            <Select
              placeholder="Select value"
              size="xs"
              data={selectedOption.options!}
              onChange={(val) => setValue(val!)}
              styles={{ root: { flex: "0 0 150px" } }}
            />
          )}
          <Button
            size="xs"
            fullWidth
            onClick={() => {
              onSubmit({
                accessor: selectedOption.accessor,
                value,
                query: query!,
                type: selectedOption.type,
              });
            }}
          >
            Submit
          </Button>
        </>
      ) : (
        <TextInput disabled size="xs" />
      )}
    </Group>
  );
};

export { FilterInput, InputType, QueryType, DataType };
