import { isEqual } from "lodash";
import { DataType, FilterValue } from "../components/FilterInput";

export const transformFiltersToQueryVariables = (filters: FilterValue[]) => {
  return filters.reduce((accumulator, currentValue) => {
    const value =
      currentValue.type !== DataType.BOOLEAN
        ? currentValue.value
        : currentValue.value === "true"
        ? true
        : false;

    return {
      ...accumulator,
      [currentValue.accessor]: {
        [currentValue.query]: value,
      },
    };
  }, {});
};

export const removeFilterFromFilterList = (
  value: FilterValue,
  list: FilterValue[]
) => {
  return list.filter((item) => {
    return !isEqual(value, item);
  });
};
