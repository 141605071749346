import {
  faChartLine,
  faFileLines,
  faHome,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SpotlightAction } from "@mantine/spotlight";

const actions: SpotlightAction[] = [
  {
    title: "Home",
    description: "Get to home page",
    onTrigger: () => console.log("Home"),
    icon: <FontAwesomeIcon icon={faHome} size="lg" />,
  },
  {
    title: "Dashboard",
    description: "Get full information about current system status",
    onTrigger: () => console.log("Dashboard"),
    icon: <FontAwesomeIcon icon={faChartLine} size="lg" />,
  },
  {
    title: "Documentation",
    description: "Visit documentation to lean more about all features",
    onTrigger: () => console.log("Documentation"),
    icon: <FontAwesomeIcon icon={faFileLines} size="lg" />,
  },
];

export { actions as spotlightActions };
