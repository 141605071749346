import { Navigate, Routes as ReactRouterRoutes, Route } from "react-router-dom";
import { useAppSelector } from "../store/store";
import { shallowEqual } from "react-redux";
import { CurrentUser } from "../store/slices/auth";
import Login from "../pages/auth/pages/Login";
import { AuthPage } from "../pages/auth/AuthPage";
import { Logout } from "../pages/auth/Logout";
import { ErrorPage } from "../pages/ErrorPage";
import { MasterLayout } from "../layouts/master/MasterLayout";
import { privateRoutes } from "./PrivateRoutes";

function RequireAuth({
  permissions,
  children,
  currentUser,
}: {
  permissions: any[];
  children: JSX.Element;
  currentUser: CurrentUser | null;
}) {
  const isAuthorized = !!currentUser;
  const currentUserRole = currentUser?.role;

  if (!isAuthorized) {
    return <Navigate to="/auth" replace />;
  }
  if (permissions.length !== 0) {
    if (!currentUserRole || !permissions.includes(currentUserRole)) {
      return <Navigate to="/auth" replace />;
    }
  }

  return children;
}

const Routes: React.FC = () => {
  const currentUser = useAppSelector(
    ({ auth }) => auth.currentUser,
    shallowEqual
  );

  return (
    <ReactRouterRoutes>
      {!!currentUser && (
        /*Redirect to /dashboard when user at `/` and authorized*/
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
      )}
      {!currentUser && (
        /*Redirect to /dashboard when user at `/` and authorized*/
        <Route path="/" element={<Navigate to="/auth" replace />} />
      )}

      <Route path="/auth" element={<AuthPage isAuthorized={!!currentUser} />}>
        <Route path="/auth" element={<Navigate replace to="/auth/login" />} />
        <Route path="/auth/login" element={<Login />} />
      </Route>
      <Route path="/logout" element={<Logout />} />

      {privateRoutes.map(({ component: Component, ...route }, i) => {
        return (
          <Route
            key={i}
            path={`${route.path}/*`}
            element={
              <RequireAuth
                permissions={route.permissions}
                currentUser={currentUser}
              >
                <MasterLayout>
                  <Component />
                </MasterLayout>
              </RequireAuth>
            }
          />
        );
      })}

      <Route path="*" element={<ErrorPage />} />
    </ReactRouterRoutes>
  );
};

export { Routes };
