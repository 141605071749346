import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./store/store";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";
import { ApolloProvider } from "@apollo/client";
import { apollo } from "./services/apollo";
import { SpotlightProvider } from "@mantine/spotlight";
import { spotlightActions } from "./services/spotlight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { themeOverride } from "./config/theme";

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <ApolloProvider client={apollo}>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={themeOverride}
        >
          <NotificationsProvider>
            <ModalsProvider>
              <SpotlightProvider
                actions={spotlightActions}
                searchIcon={
                  <FontAwesomeIcon icon={faMagnifyingGlass} size="lg" />
                }
                searchPlaceholder="Search..."
                shortcut="mod + shift + 1"
                nothingFoundMessage="Nothing found..."
              >
                <App />
              </SpotlightProvider>
            </ModalsProvider>
          </NotificationsProvider>
        </MantineProvider>
      </ApolloProvider>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
