import { AppShell, ScrollArea } from "@mantine/core";
import { DoubleNavbar } from "./components/Navbar";
import { Toolbar } from "./components/Toolbar";
import { PageDataProvider } from "./core";

const MasterLayout: React.FC = ({ children }) => {
  return (
    <PageDataProvider>
      <AppShell
        styles={{
          root: { backgroundColor: "#F4F6F9" },
          main: {
            height: "100vh",
            display: "flex",
            flexDirection: "column",
          },
        }}
        navbar={<DoubleNavbar />}
        padding={0}
      >
        <Toolbar />
        <ScrollArea sx={{ flex: "1" }}>{children}</ScrollArea>
      </AppShell>
    </PageDataProvider>
  );
};

export { MasterLayout };
