import { PageTitle } from "../../layouts/master/core";
import { useAppSelector } from "../../store/store";
import SuperadminDashboard from "./pages/SuperadminDashboard";

const DashboardPage: React.FC = () => {
  const currentUser = useAppSelector((store) => store.auth.currentUser);

  const renderAppropriatePage = () => {
    switch (currentUser?.role.name) {
      case "SUPERADMIN": {
        return <SuperadminDashboard />;
      }
      default: {
        return <></>;
      }
    }
  };

  return (
    <>
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
      {renderAppropriatePage()}
    </>
  );
};

export { DashboardPage };
