import { faFilterList } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Popover } from "@mantine/core";
import { FilterInput, FilterInputOption, FilterValue } from "./FilterInput";

export interface FilterMenuProps {
  onOpen: () => void;
  onClose: () => void;
  onSubmit: (value: FilterValue) => void;
  opened: boolean;
  options: FilterInputOption[];
}

const FilterMenu = ({
  onClose,
  onOpen,
  onSubmit,
  opened,
  options,
}: FilterMenuProps) => {
  return (
    <Popover
      opened={opened}
      onClose={onClose}
      target={
        <Button
          onClick={onOpen}
          variant="light"
          leftIcon={<FontAwesomeIcon icon={faFilterList} />}
        >
          Filter
        </Button>
      }
      width={150 + 150 + 30 + 16 * 2 + 16 * 2 + 2}
      position="bottom"
      withArrow
    >
      <FilterInput
        onSubmit={(value) => {
          onSubmit(value);
          onClose();
        }}
        options={options}
      />
    </Popover>
  );
};

export { FilterMenu };
