import { Box, Group, Text } from "@mantine/core";
import { toolbarHeight } from "../../../styles/global";
import { usePageData } from "../core";

const PageTitle: React.FC = () => {
  const { pageTitle, pageDescription, pageBreadcrumbs } = usePageData();

  if (!pageTitle && !pageDescription && !pageBreadcrumbs?.length) {
    return <></>;
  }

  return <Box>{pageTitle && <Text size="sm">{pageTitle}</Text>}</Box>;
};

const Toolbar: React.FC = () => {
  const { pageActions, pageTitle } = usePageData();

  return pageActions || pageTitle ? (
    <Group
      py="lg"
      px="xl"
      sx={(theme) => ({
        backgroundColor: theme.white,
        borderBottomLeftRadius: theme.radius.lg,
        height: `${toolbarHeight}px`,
      })}
    >
      <PageTitle />
      {pageActions ?? ""}
    </Group>
  ) : (
    <></>
  );
};

export { Toolbar };
