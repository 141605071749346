import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { graphqlUrl } from "../config/api";
import { createUploadLink } from "apollo-upload-client";

const uploadLink = createUploadLink({
  uri: graphqlUrl,
});

const httpLink = createHttpLink({
  uri: graphqlUrl,
});

const authLink = setContext((_, { headers }) => {
  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const link = ApolloLink.split(
  (operation) => operation.getContext().hasUpload,
  authLink.concat(uploadLink as any),
  authLink.concat(httpLink)
);

const apollo = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});

export { apollo };
