import { faX } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Badge } from "@mantine/core";
import { FilterValue } from "./FilterInput";

export interface FilterBadgeProps {
  value: FilterValue;
  onRemove: (value: FilterValue) => void;
}

const FilterBadge = ({ value, onRemove }: FilterBadgeProps) => {
  return (
    <Badge
      rightSection={
        <ActionIcon
          size="xs"
          color="blue"
          radius="xl"
          variant="transparent"
          onClick={() => onRemove(value)}
        >
          <FontAwesomeIcon icon={faX} color="white" />
        </ActionIcon>
      }
      sx={(theme) => ({
        paddingRight: 3,
        ":not(:last-of-type)": { marginRight: theme.spacing.sm },
      })}
      variant="filled"
    >
      {value.accessor} {value.query} {value.value}
    </Badge>
  );
};

export { FilterBadge };
