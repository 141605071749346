import {
  TextInput,
  PasswordInput,
  Paper,
  Text,
  Group,
  Button,
  createStyles,
  Image,
  Box,
} from "@mantine/core";
import { useAppDispatch } from "../../../store/store";
import { tokenReceived } from "../../../store/slices/auth";
import { useForm, Controller } from "react-hook-form";
import { displayApolloErrorNotification } from "../../../utils/apollo";
import { useNotifications } from "@mantine/notifications";
import { useSignInLazyQuery } from "../../../api";
import packageJson from "../../../../package.json";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faUser } from "@fortawesome/pro-regular-svg-icons";

const getTimePeriod = () => {
  const currentHour = new Date().getHours();

  if (currentHour < 12) {
    return "morning";
  } else if (currentHour < 18) {
    return "afternoon";
  } else {
    return "evening";
  }
};

const useStyles = createStyles((theme) => ({
  wrapper: {
    height: "100vh",
    backgroundSize: "cover",
    backgroundImage: `url(${require("../../../assets/images/venturer-bg.jpg")})`,
    backgroundPosition: "center",
  },

  form: {
    height: "100%",
    paddingTop: 80,
    maxWidth: "50%",
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    width: 120,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

interface FormValues {
  email: string;
  password: string;
}

const LoginPage = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const notifications = useNotifications();
  const navigate = useNavigate();

  const form = useForm<FormValues>({
    defaultValues: { email: "", password: "" },
  });

  const handleSubmission = ({ email, password }: FormValues) => {
    dispatch(tokenReceived("1234"));
    localStorage.setItem("token", "1234");

    navigate("/");
  };

  return (
    <div className={classes.wrapper}>
      <Paper
        className={classes.form}
        radius={0}
        p={30}
        component="form"
        onSubmit={form.handleSubmit(handleSubmission)}
      >
        <Box sx={{ maxWidth: "420px", flex: "1" }}>
          <Group direction="column" position="center" spacing={0}>
            <Image
              src={require("../../../assets/images/venturist-light.svg").default}
              sx={{ width: "200px" }}
            />
            <Text
              weight={500}
              sx={{ fontSize: "36px", lineHeight: "42px" }}
              mt={40}
            >
              Good {getTimePeriod()}!
            </Text>
            <Text
              weight={500}
              sx={{ fontSize: "36px", lineHeight: "42px" }}
              mb={50}
              color="orange"
            >
              BE A VENTURER..
            </Text>
            <Text
              weight={500}
              sx={{ fontSize: "12px", lineHeight: "42px" }}
              mb={50}
              color="pink"
            >
              Send in your proposal at <a href="mailto:marketing@venturistgroup.com"> marketing@venturistgroup.com</a>
            </Text>
          </Group>
          <Text align="center" mt={60} size="xs" color="dimmed">
            Version {packageJson.version}
          </Text>
        </Box>
      </Paper>
    </div >
  );
};

export default LoginPage;
