import React, { forwardRef, useRef, ReactElement, Ref, useMemo } from "react";
import {
  Row,
  SortingRule,
  TableOptions,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useMountedLayoutEffect,
} from "react-table";
import { faArrowDown } from "@fortawesome/pro-duotone-svg-icons";
import { useCombinedRefs } from "../utils/refs";
import {
  Box,
  Center,
  Group,
  Loader,
  Pagination,
  Select,
  Table as MantineTable,
  TableProps as MantineTableProps,
  Text,
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SelectItem } from "@mantine/core/lib/components/Select/types";

interface TableProps<T extends object = {}> extends TableOptions<T> {
  initialSortBy?: SortingRule<{}>[];
  className?: string;
  header?: ReactElement;
  pageSize?: number;
  totalCount?: number;
  rowSelection?: boolean;
  rowClick?: boolean;
  rowSelectionHandler?: (rows: Row<{}> | Row<{}>[]) => void;
  onSort?: (sortingRule: SortingRule<{}>) => void;
  onPageChanged?: (page: number) => void;
  onPageSizeChanged?: (pageSize: number) => void;
  onRowClick?: (row: Row<any>) => void;
  sortable?: boolean;
  hideFooter?: boolean;
  tableFooter?: ReactElement;
  loading?: boolean;
  mantineTableProps?: MantineTableProps;
}

const pageSizeOptions: SelectItem[] = [
  {
    label: "25",
    value: "25",
  },
  {
    label: "50",
    value: "50",
  },
  {
    label: "100",
    value: "100",
  },
  {
    label: "200",
    value: "200",
  },
];

interface IndeterminateCheckboxProps {
  indeterminate?: boolean;
}

const Table: React.FC<TableProps> = ({
  columns,
  data,
  initialSortBy,
  className,
  header,
  pageCount: controlledPageCount,
  pageSize: controlledPageSize,
  rowSelection,
  rowSelectionHandler,
  onPageChanged,
  onPageSizeChanged,
  totalCount,
  onSort,
  rowClick = false,
  onRowClick,
  sortable = false,
  hideFooter = false,
  tableFooter,
  loading = false,
  mantineTableProps,
}) => {
  const memoizedData = useMemo(() => data, [data]);
  const memoizedColumns = useMemo(() => columns, [columns]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      initialState: {
        pageIndex: 0,
        pageSize: controlledPageSize,
        sortBy: initialSortBy,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      manualSortBy: true,
      disableSortRemove: true,
      autoResetPage: false,
    },
    sortable ? useSortBy : () => {},
    usePagination,
    useRowSelect,
    (hooks) => {
      if (rowSelection) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllPageRowsSelectedProps }) => {
              const { onChange, ...props } =
                getToggleAllPageRowsSelectedProps();

              return (
                <div>
                  <IndeterminateCheckbox
                    {...props}
                    //@ts-ignore
                    onChange={(e) => {
                      if (onChange) {
                        onChange(e);

                        if (rowSelectionHandler) {
                          if (e.target.checked) {
                            rowSelectionHandler(rows);
                          } else {
                            rowSelectionHandler([]);
                          }
                        }
                      }
                    }}
                  />
                </div>
              );
            },
            Cell: ({ row }) => {
              const { onChange, ...props } = row.getToggleRowSelectedProps();

              return (
                <div>
                  <IndeterminateCheckbox
                    {...props}
                    //@ts-ignore
                    onChange={(e) => {
                      if (onChange) {
                        onChange(e);

                        if (rowSelectionHandler) {
                          rowSelectionHandler(row);
                        }
                      }
                    }}
                  />
                </div>
              );
            },
          },
          ...columns,
        ]);
      }
    }
  );

  useMountedLayoutEffect(() => {
    if (onSort) {
      onSort(sortBy[0]);
    }
  }, [onSort, sortBy]);

  const handlePageSizeChanged = (value: string) => {
    const parsedValue = parseInt(value);

    setPageSize(parsedValue);

    if (onPageSizeChanged) {
      onPageSizeChanged(parsedValue);
    }
  };

  const handleGotoPage = (page: number) => {
    gotoPage(page);

    if (onPageChanged) {
      onPageChanged(page);
    }
  };

  return (
    <Box
      className={className}
      sx={(theme) => ({
        borderRadius: theme.radius.xl,
        backgroundColor: theme.white,
      })}
    >
      {Boolean(header) && <Box>{header}</Box>}
      <MantineTable
        {...getTableProps()}
        {...mantineTableProps}
        horizontalSpacing="xs"
        verticalSpacing="xl"
        sx={{
          "thead tr th": {
            border: "none",
          },
          "tbody tr td": {
            border: "none",
          },
        }}
      >
        <Box component="thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th
                style={{
                  width: "30px",
                  maxWidth: "30px",
                  padding: "0 8px",
                }}
              />
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...(sortable
                      ? {
                          ...column.getHeaderProps(
                            column.getSortByToggleProps()
                          ),
                        }
                      : { ...column.getHeaderProps() })}
                  >
                    <Text
                      sx={{ fontSize: "11px" }}
                      transform="uppercase"
                      color="dimmed"
                      weight={500}
                    >
                      {column.render("Header")}
                    </Text>
                    {column.canSort && (
                      <Box>
                        <FontAwesomeIcon icon={faArrowDown} />
                        <FontAwesomeIcon icon={faArrowDown} />
                      </Box>
                    )}
                  </th>
                );
              })}
              <th
                style={{
                  width: "30px",
                  maxWidth: "30px",
                  padding: "0 8px",
                }}
              />
            </tr>
          ))}
        </Box>
        {!loading && rows.length ? (
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  onClick={() => onRowClick && onRowClick(row)}
                  {...row.getRowProps()}
                >
                  <td
                    style={{
                      width: "30px",
                      maxWidth: "30px",
                      padding: "0 8px",
                    }}
                  />
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                  <td
                    style={{
                      width: "30px",
                      maxWidth: "30px",
                      padding: "0 8px",
                    }}
                  />
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={headerGroups[0].headers.length + 2}>
                <Box>
                  {!rows.length && !loading ? (
                    <Center>No data</Center>
                  ) : (
                    <Loader />
                  )}
                </Box>
              </td>
            </tr>
          </tbody>
        )}
        {tableFooter && <tfoot>{tableFooter}</tfoot>}
      </MantineTable>
      {!hideFooter && (
        <Group position="apart" align="center" p="xl">
          <Text size="xs" color="dimmed">
            Showing {data.length} of {totalCount ?? data.length}
          </Text>
          <Group>
            <Select
              data={pageSizeOptions}
              placeholder="Size"
              value={pageSize ? pageSize.toString() : undefined}
              onChange={handlePageSizeChanged}
              clearable={false}
            />
            <Pagination
              style={{ margin: "0 0.5rem" }}
              page={pageIndex + 1}
              total={pageCount}
              onChange={(page) => handleGotoPage(page - 1)}
            />
          </Group>
        </Group>
      )}
    </Box>
  );
};

const IndeterminateCheckbox = forwardRef<
  HTMLInputElement,
  IndeterminateCheckboxProps
>(({ indeterminate, ...rest }, ref: Ref<HTMLInputElement>) => {
  const defaultRef = useRef(null);
  const combinedRef = useCombinedRefs(ref, defaultRef);

  useMountedLayoutEffect(() => {
    if (combinedRef?.current) {
      combinedRef.current.indeterminate = indeterminate ?? false;
    }
  }, [combinedRef, indeterminate]);

  return <input type="checkbox" ref={combinedRef} {...rest} />;
});

export default Table;
