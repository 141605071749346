import { ComponentType } from "react";
import { DashboardPage } from "../pages/dashboard/DashboardPage";
import SamplesPage from "../pages/samples/SamplesPage";

export interface Route {
  path: string;
  component: ComponentType;
  permissions: string[];
}

export const privateRoutes: Route[] = [
  {
    path: "/dashboard",
    component: DashboardPage,
    permissions: [],
  },
  {
    path: "/samples",
    component: SamplesPage,
    permissions: [],
  },
];
