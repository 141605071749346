import { useApolloClient } from "@apollo/client";
import { useLayoutEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { MeQuery } from "./api";
import { meQuery } from "./api/queries/auth";
import { LayoutSplashScreen } from "./layouts/master/core/SplashScreen";
import { Routes } from "./router/Routes";
import { authReceived, authReset, tokenReceived } from "./store/slices/auth";
import { useAppDispatch, useAppSelector } from "./store/store";

function App() {
  const apollo = useApolloClient();
  const auth = useAppSelector((store) => store.auth);
  const dispatch = useAppDispatch();

  const [authLoaded, setAuthLoaded] = useState(false);

  useLayoutEffect(() => {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");

    if (token) {
      if (!auth.currentUser) {
        dispatch(tokenReceived(token));
      }
    } else {
      setAuthLoaded(true);
    }
  }, [dispatch, auth.currentUser]);

  useLayoutEffect(() => {
    if (auth.token) {
      (async () => {
        try {
          dispatch(
            authReceived({
              id: "1234",
              email: "dev@pow.asia",
              name: "Dev",
              role: {
                id: "1234",
                name: "SUPERADMIN",
                permissions: [],
              },
            })
          );

          setAuthLoaded(true);
        } catch (err) {
          console.log(err);
          dispatch(authReset(null));
          setAuthLoaded(true);
        }
      })();
    }
  }, [auth.token, dispatch, apollo]);

  if (!authLoaded) {
    return <LayoutSplashScreen />;
  }

  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
}

export default App;
