import {
  faGaugeHigh,
  faMemo,
  faSliders,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface BaseLink {
  icon: JSX.Element;
  label: string;
  path: string;
}

export interface Section {
  label: string;
}

export type RootLink = BaseLink;

export type SubLink = BaseLink;

export const rootLinksData: RootLink[] = [
  {
    icon: <FontAwesomeIcon icon={faGaugeHigh} size="lg" />,
    label: "Console",
    path: "/",
  },
  {
    icon: <FontAwesomeIcon icon={faSliders} size="lg" />,
    label: "Settings",
    path: "/settings",
  },
];

export const subLinksData: (SubLink | Section)[] = [
  {
    icon: <FontAwesomeIcon icon={faGaugeHigh} size="lg" />,
    label: "Dashboard",
    path: "/dashboard",
  },
  { label: "Samples" },
  {
    icon: <FontAwesomeIcon icon={faMemo} size="lg" />,
    label: "Table",
    path: "/samples/table",
  },
];
