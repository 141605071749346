import { Ref, useRef, useEffect } from 'react';

export const useCombinedRefs = <T extends any>(
  ...refs: Array<Ref<T>>
): React.MutableRefObject<any> => {
  const targetRef = useRef();

  useEffect(() => {
    refs.forEach((ref: any) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};
