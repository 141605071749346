const production = {
  graphql: `${process.env.REACT_APP_API_URL}/graphql`,
  rest: process.env.REACT_APP_API_URL,
};

const development = {
  graphql: "http://localhost:4000/graphql",
  rest: "http://localhost:4000",
};

export const restUrl =
  process.env.NODE_ENV === "development" ? development.rest : production.rest;

export const graphqlUrl =
  process.env.NODE_ENV === "development"
    ? development.graphql
    : production.graphql;
