const calculatePageCount = (
  totalCount: number | undefined,
  take: number | undefined
) => {
  if (totalCount && take) {
    return Math.ceil(totalCount / take);
  } else {
    return 0;
  }
};

const handlePageSizeChanged =
  (dispatch: React.Dispatch<React.SetStateAction<any>>) => (size: number) => {
    dispatch((prev: any) => ({
      ...prev,
      take: size,
    }));
  };

const handlePageChanged =
  (dispatch: React.Dispatch<React.SetStateAction<any>>) => (page: number) => {
    dispatch((prev: any) => ({
      ...prev,
      skip: page * prev.take,
    }));
  };

const handleWhereReset =
  (
    dispatch: React.Dispatch<React.SetStateAction<any>>,
    initialState?: any,
    constant?: any
  ) =>
  () => {
    dispatch((prev: any) => ({
      ...prev,
      where: initialState?.where || constant || {},
    }));
  };

export {
  calculatePageCount,
  handlePageSizeChanged,
  handlePageChanged,
  handleWhereReset,
};
