import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authReset } from "../../store/slices/auth";
import { useAppDispatch } from "../../store/store";

export function Logout() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(authReset(null));
    navigate("/auth/login");
  }, [dispatch, navigate]);

  return <div></div>;
}
