import { MantineThemeOverride } from "@mantine/core";

export const themeOverride: MantineThemeOverride = {
  colors: {
    azul: [
      "#F2F4FB",
      "#DCDFEC",
      "#BEC4E0",
      "#9FAADA",
      "#7D8EDA",
      "#5770E1",
      "#2B4FF0",
      "#2445D6",
      "#3D4F9F",
      "#414F89",
    ],
  },
  fontFamily: "'Fira Sans', sans-serif",
  primaryColor: "azul",
  other: {
    background: "#F4F6F9",
  },
};
