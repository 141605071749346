import { useMemo } from "react";
import {
  createStyles,
  Navbar,
  Tooltip,
  Image,
  Group,
  Text,
  Box,
  Anchor,
  ScrollArea,
} from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faGlobeAsia,
  faMagnifyingGlass,
} from "@fortawesome/pro-regular-svg-icons";
import { Link, useLocation } from "react-router-dom";
import {
  RootLink as RootLinkType,
  rootLinksData,
  Section,
  SubLink as SubLinkType,
  subLinksData,
} from "../NavbarRoutes";
import { useSpotlight } from "@mantine/spotlight";
import { navbarWidth } from "../../../styles/global";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  rootLinks: {
    flex: "0 0 80px",
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors[theme.primaryColor][6],
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  subLinks: {
    flex: 1,
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.other.background,
    height: "100vh",
  },
  rootLinksContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  rootLink: {
    width: 50,
    height: 50,
    borderRadius: theme.radius.md,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.white,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors[theme.primaryColor][7],
    },
  },
  rootLinkActive: {
    "&, &:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.fn.rgba(theme.colors[theme.primaryColor][9], 0.25)
          : theme.colors[theme.primaryColor][7],
      color:
        theme.colorScheme === "dark"
          ? theme.colors[theme.primaryColor][4]
          : theme.white,
    },
  },
  title: {
    boxSizing: "border-box",
    marginBottom: theme.spacing.xl,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    padding: theme.spacing.md,
    paddingTop: 18,
    height: 60,
    borderBottom: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
      }`,
  },
  logo: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    height: 60,
    paddingTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl,
  },
  subLink: {
    display: "flex",
    borderRadius: theme.radius.md,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[6],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    padding: `${theme.spacing.sm}px ${theme.spacing.xl}px`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[1],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },
  subLinkActive: {
    "&, &:hover": {
      borderLeftColor:
        theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 7 : 5],
      backgroundColor: theme.white,
      color: theme.colors.gray[9],
    },
  },
  subLinkIconActive: {
    "&, &:hover": {
      color: theme.colors[theme.primaryColor][6],
    },
  },
}));

interface RootLinkTooltipProps {
  label: string;
}

const RootLinkTooltip: React.FC<RootLinkTooltipProps> = ({
  label,
  children,
}) => {
  return (
    <Tooltip
      label={label}
      position="right"
      withArrow
      transitionDuration={0}
      sx={(theme) => ({
        "&:not(:last-of-type)": {
          marginBottom: theme.spacing.md,
        },
      })}
    >
      {children}
    </Tooltip>
  );
};

const RootLink = ({
  icon,
  label,
  active,
  path,
}: RootLinkType & {
  active?: boolean;
}) => {
  const { classes, cx } = useStyles();

  return (
    <RootLinkTooltip label={label}>
      <Link
        to={path}
        className={cx(classes.rootLink, {
          [classes.rootLinkActive]: !!active,
        })}
      >
        {icon}
      </Link>
    </RootLinkTooltip>
  );
};

const SubLink = ({
  icon,
  label,
  active,
  path,
}: SubLinkType & { active?: boolean }) => {
  const { classes, cx } = useStyles();

  return (
    <Link
      className={cx(classes.subLink, {
        [classes.subLinkActive]: !!active,
      })}
      to={path}
    >
      <Group noWrap>
        <Box
          mr={6}
          sx={{ minWidth: "22px" }}
          className={cx({
            [classes.subLinkIconActive]: !!active,
          })}
        >
          {icon}
        </Box>
        <Box>{label}</Box>
      </Group>
    </Link>
  );
};

export function DoubleNavbar() {
  const { classes } = useStyles();
  const spotlight = useSpotlight();

  const { pathname } = useLocation();

  const rootLinks = rootLinksData.map((link, index) => (
    <RootLink {...link} active={pathname.includes(link.path)} key={index} />
  ));

  const subLinks = useMemo(
    () =>
      subLinksData.map((link, index) => {
        if ((link as SubLinkType).path) {
          const typedLink = link as SubLinkType;

          return (
            <SubLink
              {...typedLink}
              active={pathname === typedLink.path}
              key={index}
            />
          );
        } else {
          const section = link as Section;

          return (
            <Text
              sx={(theme) => ({
                textTransform: "uppercase",
                color: theme.colors.gray[5],
              })}
              size="xs"
              weight={600}
              mb="sm"
              pl="sm"
              key={index}
              mt={30}
            >
              {section.label}
            </Text>
          );
        }
      }) || [],
    [pathname]
  );

  return (
    <Navbar
      width={{ sm: navbarWidth }}
      styles={{ root: { borderRight: "none" } }}
    >
      <Navbar.Section grow className={classes.wrapper}>
        <div className={classes.rootLinks}>
          <div className={classes.logo}>
            <Image
              sx={{ maxWidth: "30px" }}
              src={
                require("../../../assets/images/venturist-light.svg").default
              }
            />
          </div>
          <div className={classes.rootLinksContainer}>
            {rootLinks[0]}
            <RootLinkTooltip label="Visit Web App">
              <Anchor
                href="https://endorse.my"
                className={classes.rootLink}
                sx={{ cursor: "pointer" }}
                target="_blank"
              >
                <FontAwesomeIcon icon={faGlobeAsia} size="lg" />
              </Anchor>
            </RootLinkTooltip>
            {rootLinks.slice(1)}
            <RootLinkTooltip label="Spotlight">
              <Box
                className={classes.rootLink}
                sx={{ cursor: "pointer" }}
                onClick={() => spotlight.openSpotlight()}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} size="lg" />
              </Box>
            </RootLinkTooltip>
          </div>
          <Group direction="column" align="center" spacing={0} pb="xl">
            <RootLink
              label="Logout"
              path="/logout"
              icon={
                <FontAwesomeIcon icon={faArrowRightFromBracket} size="lg" />
              }
            />
          </Group>
        </div>
        <ScrollArea className={classes.subLinks} py={20} px={20}>
          {subLinks}
        </ScrollArea>
      </Navbar.Section>
    </Navbar>
  );
}
